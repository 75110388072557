import React, { useRef } from "react";
import "../../Assets/css/contactus.css";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import Helmet from "react-helmet";
import FreeTrailSmoImg from "../../Assets/images/free-trail-smo/free-trail-smo.png";
import "../../Assets/css/consultation.css";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from "react-router-dom";

const FreeTrialSmo = () => {
  var planName= useLocation();


  const captchaRef = useRef(null)
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [business, setBusiness] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);

  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }

  const SubmitFreetrial = (e) => {
    e.preventDefault();
    if ( phone.length>7) {
    const data = {
      first_name: firstname,
      email: email,
      phone: `+${phone}`,
      business_name: business,
      plan_type: planName.state===undefined ? "FREEMIUM" :  planName.state.planName,
    };  
    setLoad(true);
    axios
      .post(`${baseURL}/membership/plan_request`, data, { headers: postHeader })
      .then((res) => {
        if (res.data.status == 1) {
          swal(res.data.message, { icon: "success" });
          setFirstname("");
          setLastname("");
          setPhone("");
          setEmail("");
          setMessage("");
          setBusiness("");
          setWebsite("");
          setLoad(false);
              captchaRef.current.reset();
        } else {
          swal(res.data.message, { icon: "warning" });
          setLoad(false);
              captchaRef.current.reset();
        }
      })
      .catch((error) => {
        if (error) {
          setLoad(false);
        }
      }); }else{
        setPhoneError('Please Enter Valid Number');}
     
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Free SMO Services - Webnet</title>
        <meta
          name="description"
          content="We Offer Consultation To Grow Your Business Online"
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_contactus_section position-relative">
        <div className="bw_all_banner bw_contact_banner bw_consultation_banner">
          <Container>
            <Row>
              <Col
                sm={12}
                md={12}
                lg={12}
                className="bw_consultation_banner_text"
              >
                <div>
                  <h1 className="text-center text-white">
                    Subscribe To Freemium
                  </h1>
                  <p
                    className="text-white"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    We’re All Set to You Strategized Social Media Content For
                    Free!
                  </p>
                </div>
              </Col>
              {/* <Col sm={12} md={6} lg={6} className="bw_consultation_banner_img">
                <LazyLoadImage src={FreeTrailSmoImg} alt="Consulation Banner Image" />
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="bw_new_position_box bw_consulation_from_position mb-5">
          <Container>
            <Row className="w-100 m-auto px-0">
              <Col className="px-0">
                <div
                  className="bw_contact_form_outer_box"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h2>Let's Book An Appointment</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitFreetrial}
                    >
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}  
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email address*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number*</Form.Label>
                            <PhoneInput
                            disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={setPhone}
                              // onlyCountries={[
                              //   "us",
                              //   "cn",
                              //   "in",
                              //   "au",
                              //   "jp",
                              //   "hk",
                              //   "kw",
                              //   "gb",
                              //   "es",
                              // ]}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}
                            />
                            {!phoneError.length?'':<p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company Name"
                              value={business}
                              onChange={(e) => setBusiness(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Link Of Your Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Website Url"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}
                        {/* <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Your Message*"
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}
                        {/* <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col> */}
                      </Row>
                      <Row>
                        <button type="submit" >
                              {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#000"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "Subscribe"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(FreeTrialSmo);
