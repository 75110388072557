import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import '../../Assets/css/aboutus.css'
import usaFlag from "../../Assets/images/about-us/usa-flag.png";
import AshishPorfileImg from "../../Assets/images/about-us/ashish-new.jpg";
import PhoneImg from "../../Assets/images/about-us/phone.png";
import EmailImg from "../../Assets/images/about-us/email.png";
import InstagramImg from '../../Assets/images/social-img/instagram.png'
import WhatsappImg from '../../Assets/images/social-img/whatsapp.png'
import LinkedinImg from '../../Assets/images/social-img/linkedin.png'
import title from '../../Assets/images/about-us/title-miami-desktop.png'
import title_mb from '../../Assets/images/about-us/title-miami-mobile.png'
import counrties from '../../Assets/images/about-us/counrties.png'
import { isMobile, isMobileOnly } from 'react-device-detect';


const AboutAshishJangir = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    const content = "I am Ashish Jangir, CTO and Co-founder of Belgium WebNet. With an illustrious 10-year journey in the industry, my initial exposure to the world of Diamonds & Jewelry came during my tenure as a professional in the field. Surrounded by the world of jewelry and diamonds, together with my team we envisioned a way to elevate the industry's growth. After extensive brainstorming and strategic planning, Belgium WebNet was born. Today, Belgium WebNet takes pride in its team of 60+ talented professionals, dedicated to serving more than 150 clients across the Diamond & Jewelry sector in various countries like the USA, UK, and Dubai. Drawing from our vast on-field experience, we understand the immense possibilities that digital transformation can bring to this industry. We look forward to the opportunity of working with you! Book an appointment with me, and let's create a bright digital future for your jewelry business."
    const content1 = "I am Ashish Jangir, CTO and Co-founder of Belgium WebNet. With an illustrious 10-year journey in the industry, my initial exposure to the world of Diamonds & Jewelry came during my tenure as a professional in the field. Surrounded by the world of jewelry and diamonds, together with my team we envisioned a way to elevate the industry's growth. After extensive brainstorming and strategic planning, Belgium WebNet was born. Today, Belgium WebNet takes pride in its team of 60+ talented professionals, dedicated to serving more than 150 clients across the Diamond & Jewelry sector in various countries like the USA, UK, and Dubai. Drawing from our vast on-field experience, we understand the immense possibilities that digital transformation can bring to this industry. We look forward to the opportunity of working with you! Book an appointment with me, and let's create a bright digital future for your jewelry business."

    const displayContent = isExpanded ? content : content.slice(0, 111);
    const displayContent1 = isExpanded ? content1 : content1.slice(0, 269);






    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);

        };

    }, []);





    const year = new Date().getFullYear()
    const month = new Date().getMonth()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Ashish Jangir - Book-An-Appointment - Belgium Webnet
                </title>
                <meta
                    name="description"
                    content=" "
                ></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
           
            <div className='bw_about_aash'>
          
                <Container className='bw_custome_container_header1 bw_custome_container_ash_about '>
                    {/* {isMobileOnly ?
                        <img className='m-auto d-table img-fluid ashi_visit_title' src={title_mb} alt='Ashish Jangir - Book-An-Appointment - Belgium Webnet' /> :
                        <img className='m-auto d-table img-fluid ashi_visit_title' src={title} alt='Ashish Jangir - Book-An-Appointment - Belgium Webnet' />
                    } */}
                      <div className='bw_new_heading_for_ashish25'>
                <Row>
                    <Col sm={12} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }}>
                        <div className='bw_ash_text_box2025'>
                           <div className='bw_ash_text_box2025_dflex'>
                            <Image src={usaFlag} alt="USA flag" className='img-thumbnail img-fluid' />
                           <h1>
                                USA Visit: JCK Las Vegas & New York</h1>
                           </div>
                            <h2>May 26 To June 13, 2025
                            </h2>
                        </div>
                    </Col>
                </Row>
            </div>
                    <Row className='bw_new_ash_About_row'>
                        <Col sm={12} md={12} lg={3} className='bw_new_ash_About_col_3'>
                            <div className='bw_ash_profile_box1 bw_ash_profile_box_new'>

                                <Row>
                                    <Col xs={4} sm={4} md={12} lg={12} className='bw_ashi_img_pading_mobile_view'>
                                        <div className='bw_img_assh_profile'>
                                            <Image src={AshishPorfileImg} alt="Ashish Jangir Profile Photo" className='img-thumbnail img-fluid' />
                                        </div>
                                    </Col>
                                    <Col xs={8} sm={8} md={12} lg={12}>
                                        <div className='bw_ash_text_box'>
                                            <h2> Ashish Jangir</h2>
                                            <h5>CTO & Co-Founder</h5>
                                            <div className='bw_ashish_social'>
                                                <a rel="noreferrer" href="https://www.linkedin.com/in/ashish-jangir-5735527a/" target="_blank">
                                                    <Image src={LinkedinImg} alt="Linkedin Image" />
                                                </a>
                                                <a rel="noreferrer" href="https://www.instagram.com/belgium_webnet/" target="_blank">
                                                    <Image src={InstagramImg} alt="Instagram Image" />
                                                </a>
                                                <a rel="noreferrer" href="https://wa.me/+16469929024" target="_blank">
                                                    <Image src={WhatsappImg} alt="Phone Image" />
                                                </a>
                                                <a href='tel:+1 6469929024'>
                                                    <Image src={PhoneImg} alt="Phone Image" />
                                                </a>
                                                <a href='mailto:ashish@belgiumwebnet.com'>
                                                    <Image src={EmailImg} alt="Email Image" />
                                                </a>
                                            </div>

                                            <div className='bw_Professional_Experience desktop_content'>
                                                {/* <p>I am Ashish Jangir, CTO and Co-founder of Belgium WebNet. With an illustrious 10-year journey in the industry, my initial exposure to the world of Diamonds & Jewelry came during my tenure as a professional in the field.</p>
                                                <p>Surrounded by the world of jewelry and diamonds, together with my team we envisioned a way to elevate the industry's growth. After extensive brainstorming and strategic planning, Belgium WebNet was born. Today, Belgium WebNet takes pride in its team of 60+ talented professionals, dedicated to serving more than 150 clients across the Diamond & Jewelry sector in various countries like the USA, UK, and Dubai. Drawing from our vast on-field experience, we understand the immense possibilities that digital transformation can bring to this industry.</p>
                                                <p>We look forward to the opportunity of working with you! Book an appointment with me, and let's create a bright digital future for your jewelry business.</p> */}
                                                {/* <p>{displayContent1}</p>
                                                {content1.length > 250 && (
                                                    <div className='bw_ash_pera_read_more' onClick={toggleExpansion}>
                                                        {isExpanded ? 'Read Less' : 'Read More'}
                                                    </div>
                                                )} */}
                                            </div>
                                            {/* <div className=' bw_Professional_Experience bw_ashi_mobile_content_read_more'>
                                                <p>{displayContent}</p>
                                                {content.length > 112 && (
                                                    <div className='bw_ash_pera_read_more' onClick={toggleExpansion}>
                                                        {isExpanded ? 'Read Less' : 'Read More'}
                                                    </div>
                                                )}
                                            </div> */}
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        {/* <div className=' bw_Professional_Experience bw_ashi_mobile_content_read_more'>
                                            <p>{displayContent}</p>
                                            {content.length > 111 && (
                                                <div className='bw_ash_pera_read_more' onClick={toggleExpansion}>
                                                    {isExpanded ? 'Read Less' : 'Read More'}
                                                </div>
                                            )}
                                        </div> */}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={9} className='bw_new_ash_About_col_9'>
                            <div className='bw_calendly_margin_top'>
                                <div className="calendly-inline-widget" data-url={`https://calendly.com/ashishjangir/30min?embed_domain=localhost%3A3000&embed_type=Inline&back=1&month=${year}-${month}`} style={{ minWidth: '320px', height: '658px' }}></div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <p className='bw_aj_content'>CTO and Co-founder of Belgium WebNet. With an illustrious 10-year journey in the jewelry industry, my initial exposure to the world of Diamonds & Jewelry came during my tenure as a professional in the field. Surrounded by the world of jewelry and diamonds, together with my team we envisioned a way to elevate the industry's growth. After extensive brainstorming and strategic planning, Belgium WebNet was born, a IT company who can help you in <b>E-Commerce Website development</b> with your <b>Digital Marketing</b> needs. Today, Belgium WebNet takes pride in its team of <b>80+ talented professionals</b>, dedicated to serving more than <b>200 clients</b> across the Diamond & Jewelry sector in various countries like the USA, UK, Dubai and India. Drawing from our vast on-field experience, we understand the immense possibilities that digital transformation can bring to this industry. We look forward to the opportunity of working with you! Book an appointment with me, and let's create a bright digital future for your jewelry business.</p>
                        </Col>
                    </Row>




                </Container>
            </div>

            <div className='client-country text-center'>
                <h3>Our Clients around the world</h3>
                <img className='img-fluid' src={counrties} alt='Our Clients' />
            </div>
        </>
    )
}

export default AboutAshishJangir