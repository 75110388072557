import React, { useState } from "react";
import "../../Assets/css/diamondJewelryWebDevelopment.css";
import Helmet from "react-helmet";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "../../Assets/css/plansPricingSeo.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import { FiCheck } from "react-icons/fi";
import ClearIcon from "@mui/icons-material/Clear";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";
import ArrowImpImg from "../../Assets/images/portfolio-img/get-arrow-img.png";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function TabPanel(props) {
  const { children, value, valueInner, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueInner: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PlansPricingsSeo = () => {
  const history = useHistory();
  const handleClick = () => history.push("/package-form");

  const [value, setValue] = React.useState(0);
  const [valueInner, setValueInner] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangenew = (event, newValueInner) => {
    setValueInner(newValueInner);
  };
  const [currency, setCurrency] = useState("INR");


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Best Search Engine Optimization Company - Belgium WebNet</title>
        <meta
          name="description"
          content="Partner with Belgium WebNet, a leading SEO company, to grow your jewelry business. Schedule an appointment for a comprehensive & effective optimization strategy!."
        ></meta>
        <meta name="keywords" content="Best search engine optimization company"></meta>
      </Helmet>
      <section className="bwn_seo_pricing_section">
        <div className="bw_pricing_section">
          <div className="bw_all_banner bw_banner_pricing">
            <Container className="bw_custome_container">
              <Row className="w-100 m-auto">
                <Col>
                  <h1 className="bw_banner_heading">
                    Search Engine Optimization
                  </h1>
                  <p className="bw_banner_sub_heading">
                    Choose a plan to avail our services and get started with
                    your transformation journey today.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="bw_pricing_tabs_section">
            <Container className="bw_custome_container_pricing position-relative">
              <div className="bw_pricing_tabs_box bw_only_month_tab text-center position-relative">
                
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab label="Monthly" {...a11yProps(0)} />
                  <Tab label="Annually" {...a11yProps(1)} />
                </Tabs>

              </div>
              <div className="bwn_first_month_free_text text-center">
                  <div className="position-relative">
                    <button className="bwn_get_on_month_free_button">Annually Get 1 Month Free</button>
                    {/* <Image src={ArrowImpImg}></Image> */}
                  </div>
                </div>
              {window.location.pathname.includes("-inr") ? <Row className="w-100 m-0">
               <Col xl={1} lg={2} md={12}  xs={10} className="inr_input_main">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                      size={"small"}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"INR"}>INR</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                    </Select>
                  </FormControl>

                </Col>

              </Row> : ""}
              <TabPanel
                value={value}
                index={0}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 15000 : 200} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 22500 : 350} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 25000 : 500} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>
                            <td>
                              <div>
                                Homepage
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 2 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 4 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>5 Keywords</td>
                            <td>8 Keywords</td>
                            <td>12 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>
                            <td>
                              <div>
                                40 Links
                              </div>
                            </td>
                            <td>
                              <div>
                                80 Links
                              </div>
                            </td>
                            <td>
                              <div>
                                130 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>2 Blogs</td>
                            <td>2 Blogs</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>
                            <td>1 Blog</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>
                            <td><div className="bw_pricing_checkicon">
                              <FiCheck />
                            </div></td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap_class">
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />  
                              </div>
                                <span> <a href="tel:+1 646 992 9024" className="text-dark">Call To Get Details!</a></span>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label="RHODIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 15000 : 200} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>
                            <td>
                              <div>
                                Homepage
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>5 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>
                            <td>
                              <div>
                                40 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 22500 : 350} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>
                            <td>
                              <div>
                                Any 2 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>8 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>

                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>
                            <td>
                              <div>
                                80 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>

                            <td>2 Blogs</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>
                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>

                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>

                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>

                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 25000 : 500} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : " $299"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>

                            <td>
                              <div>
                                Any 4 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>12 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>

                            <td>
                              <div>
                                130 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>

                            <td>2 Blogs</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>

                            <td>1 Blog</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>

                            <td><div className="bw_pricing_checkicon">
                              <FiCheck />
                            </div></td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>

                            <td>
                            <div className="d-flex align-items-center gap_class">
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />  
                              </div>
                                <span> <a href="tel:+1 646 992 9024" className="text-dark">Call To Get Details!</a></span>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 165000 : 2200} duration={.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 247500 : 3850} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 275000 : 5500} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>
                            <td>
                              <div>
                                Homepage
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 2 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 4 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>5 Keywords</td>
                            <td>8 Keywords</td>
                            <td>12 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>
                            <td>
                              <div>
                                40 Links
                              </div>
                            </td>
                            <td>
                              <div>
                                80 Links
                              </div>
                            </td>
                            <td>
                              <div>
                                130 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>2 Blogs</td>
                            <td>2 Blogs</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>
                            <td>1 Blog</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>
                            <td><div className="bw_pricing_checkicon">
                              <FiCheck />
                            </div></td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                            <div className="d-flex align-items-center gap_class">
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />  
                              </div>
                                <span> <a href="tel:+1 646 992 9024" className="text-dark">Call To Get Details!</a></span>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label="RHODIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 165000 : 2200} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>
                            <td>
                              <div>
                                Homepage
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>5 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>
                            <td>
                              <div>
                                Any 3 Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>
                            <td>
                              <div>
                                40 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 247500 : 3850} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : " $299"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>
                            <td>
                              <div>
                                Any 2 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>8 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>

                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>

                            <td>
                              <div>
                                Any 6 Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>
                            <td>
                              <div>
                                80 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>

                            <td>2 Blogs</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>
                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>

                            <td><div className="bw_pricing_checkicon bw_bg_light_cancel">
                              <ClearIcon />
                            </div></td>

                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>

                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>

                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-inr") && currency == "INR" ? 275000 : 5500} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-inr") && currency == "INR" ? "₹" : "$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-inr") && currency == "INR" ? "₹20000" : "$299"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Audit & Initial Report{" "}
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website Content Writing
                            </td>

                            <td>
                              <div>
                                Any 4 Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Keyword Research & Implementation
                            </td>
                            <td>12 Keywords</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup Google Analytics, WebMaster & Google My Business
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Weekly Posting on Google My Business
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Setup & analysis of Google Tag Manager
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Sitemap Xml</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Robots.txt</td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Meta Tags (Title & Description) </td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Alt Image Tags optimization</td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Headings Optimization</td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>

                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Canonicalizations
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Checking 404 Errors and 301 Redirection
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Analysis of minified JSS & CSS on website
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              External Link Analysis & Disavow
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Duplicate Content Analysis/Plagiarism
                            </td>

                            <td>
                              <div>
                                Menu Heading Pages
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Schema Code Generate
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Link Building
                            </td>

                            <td>
                              <div>
                                130 Links
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Web 2.0
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Off page Blog Writing & Submission</td>

                            <td>2 Blogs</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">On Page Blog Writing</td>

                            <td>1 Blog</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Competitor Analysis</td>

                            <td><div className="bw_pricing_checkicon">
                              <FiCheck />
                            </div></td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Adwords Management
                            </td>

                            <td>
                            <div className="d-flex align-items-center gap_class">
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />  
                              </div>
                                <span> <a href="tel:+1 646 992 9024" className="text-dark">Call To Get Details!</a></span>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Consultation & Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
              </TabPanel>
              <Row className="w-100 m-auto">
                <Col>
                  <ul className="bw_green_djdinr_list_style pl-4">
                    <li>
                      To inculcate keywords apart from what is mentioned in the chosen plan, a separate custom quotation will be shared.
                    </li>
                      {window.location.pathname.includes("-inr") && currency == "INR"  ? <li>Above prices are exclusive of GST.</li> : '' }
                  </ul>
                </Col>
              </Row>
              {/* <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/diamond-jewelry-web-development"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      Website Development Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-smo"
                      className="bw_prcing_btn mb-2"
                    >
                      Social Media Marketing Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row> */}
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(PlansPricingsSeo);
