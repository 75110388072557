import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { baseURL, isLogin, postHeader, user } from '../../../Helpers/request';
// import { cartlengthvalue } from '../Reducer/cartReducer';
// import { whishlistlength } from '../Reducer/wishlistReducer';
// import { sessionId } from '../Reducer/homeReducer';
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { Col, Row, Form } from 'react-bootstrap';
import PhoneInput from "react-phone-input-2";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Thankyou from '../../Pages/Thankyou';


export default function Stripe(props) {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setcompanyName] = useState("");
    const [cardHolderName, setCardHolderName] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [saddress, setSaddress] = useState("");
    const [scountry, setScountry] = useState("");
    const [sstate, setSstate] = useState("");

    //const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const handlelogout = () => {
        // e.preventDefault();
        localStorage.removeItem('bw-user');
        // dispatch(whishlistlength(0))
        // dispatch(cartlengthvalue(0))
        // dispatch(sessionId(''))
        history.push("/")
        //window.location.reload(true);
    }

    const handleSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, token } = await stripe.createToken(cardElement);

        if (!error) {


            let payload = {
                company_name: companyName,
                email: email,
                phone: phone,
                card_holder_name: cardHolderName,
                country: scountry,
                state: sstate,
                city: city,
                zip: postalCode,
                adress: saddress,
                token: token.id
            }

            axios.post(baseURL + "/Stripepay/offer", payload, {
                headers: postHeader,
            })
                .then((response) => {
                    if (response.data.status == 1) {
                        setLoader(false);
                        //dispatch(cartlengthvalue(0));
                        sessionStorage.removeItem("bw-checkoutdata");
                        history.push('/thankyou');
                    }
                    else if (response.data.status == 2) {
                        setLoader(false);
                        // handlelogout();
                    } else {
                        setLoader(false);
                        return swal(response.data.message, "", "error");
                    }
                })
                .catch((err) => {
                    setLoader(false);
                    console.log(err);
                });
        } else {
            setLoader(false);
            console.log(error);
        }
    };

    return (
        <>
            {/* {loader ? <div className="bj_loder position-relative">
                <Loader className="loder_checkout" /> 
            </div> : ""} */}
            <form onSubmit={handleSubmit} className='bj_stripe_form_card bw_contact_us_form'>
                <h4 className='text-left mb-3 pb-3'>Register Now</h4>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Company Name"
                                value={companyName}
                                onChange={(e) => setcompanyName(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="email"
                                placeholder="Email Id"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <PhoneInput
                                disableSearchIcon={true}
                                enableSearch={true}
                                country={"us"}
                                value={phone}
                                onChange={setPhone}
                                // onlyCountries={[
                                //     "us",
                                //     "cn",
                                //     "in",
                                //     "au",
                                //     "jp",
                                //     "hk",
                                //     "kw",
                                //     "gb",
                                //     "es",
                                // ]}
                                inputProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: false,
                                }}
                                isValid={(value, country) => {
                                    if (value.length < 10) {
                                        return "Please Enter Valid Number";
                                    } else {
                                        return true;
                                    }
                                }}
                                required
                            />
                        </Form.Group>
                    </Col>


                    <Col xs={12} sm={6} className="rcs_custom_padding rcs_custom_select_country">
                        <CountryDropdown
                            defaultOptionLabel="Select a Country"
                            value={scountry}
                            required
                            onChange={(val) => setScountry(val)}
                        />
                    </Col>
                    <Col xs={12} sm={6} className="rcs_custom_padding rcs_custom_select_country">
                        <RegionDropdown
                            blankOptionLabel="No Country Selected"
                            defaultOptionLabel="Select a State/Province"
                            country={scountry}
                            required
                            value={sstate}
                            onChange={(val) => setSstate(val)}
                        />
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="text"
                                placeholder="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Address"
                                value={saddress}
                                onChange={(e) => setSaddress(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="number"
                                placeholder="Postal Code"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                pattern="\d{5}"
                                required
                            />
                        </Form.Group>
                    </Col>
              
                </Row>
                <h4 className='text-left pb-2 mt-3'>Insert Card Detail</h4>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Card Holder Name"
                                value={cardHolderName}
                                onChange={(e) => setCardHolderName(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6} sm={12} md={6} xl={6}>
                        <CardElement className='card-info' />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                    </Col>
                    <Col lg={6} sm={12} md={12} xl={6} className='pt-2'>

                        <div className='d-flex justify-content-end flex-wrap'>
                            <p className='w-100 text-right mt-3'><b>Instagram Setup Charges : $100</b></p>
                            <div>
                                <button className='bj_pay_button'>Pay Now</button>
                            </div>
                        </div>

                    </Col>
                </Row>


            </form>
        </>
    );

}
