import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PageNotFound from './Pages/PageNotFound'

const Redirection = () => {

  return (
    <Switch>
      <Route path="/freemium" render={() => (<Redirect to="/free-plans-smo" />)} />
      <Route path="/integration" render={() => (<Redirect to="/integrations" />)} />
      <Route path="/package-form" render={() => (<Redirect to="/consultation" />)} />
      <Route path="/services" render={() => (<Redirect to="/digital-marketing" />)} />
      <Route path="/heres-why-jewelry-websites-must-be-frequently-updated" render={() => (<Redirect to="/blogs/heres-why-jewelry-websites-must-be-frequently-updated" />)} />
      <Route path="/the-importance-of-instagram-hashtags-and-how-to-use-them" render={() => (<Redirect to="/blogs/the-importance-of-instagram-hashtags-and-how-to-use-them" />)} />
      <Route path="/6-tips-to-make-your-jewelry-business-stand-out-on-instagram" render={() => (<Redirect to="/blogs/6-tips-to-make-your-jewelry-business-stand-out-on-instagram" />)} />
      <Route path="/belgium-webnet" render={() => (<Redirect to="/blogs/" />)} />
      <Route path="/diamond-jewelery-web-development" render={() => (<Redirect to="/b2c-pricing" />)} />
      <Route path="/lake-hillier" render={() => (<Redirect to="/diamond-jewelry-designs/" />)} />
      <Route path="/diamond-jewelry-web-development" render={() => (<Redirect to="/b2c-pricing" />)} />
      <Route path='*' exact={true} component={PageNotFound} />

    </Switch>
  )
}

export default Redirection
