import React, { useRef, useState } from "react";
import "../../Assets/css/services.css";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import NewBranding from "../../Components/Front/NewBranding.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TogetherImg from "../../Assets/images/home/together-img.jpg";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link, useHistory } from "react-router-dom";

import ServicesImg1 from "../../Assets/images/services-img/webdev.png";
import ServicesImg2 from "../../Assets/images/services-img/ppc.png";
import ServicesImg3 from "../../Assets/images/services-img/shopify.png";
import ServicesImg4 from "../../Assets/images/services-img/smm.png";
import MarketingImg from "../../Assets/images/services-img/marketing.png";
import ServicesImg5 from "../../Assets/images/services-img/seo.png";
import WebDesignImg from "../../Assets/images/services-img/web_design.png";
import SeoImg from "../../Assets/images/services-img/seo_1.png";
import GoogleImg from "../../Assets/images/services-img/google.png";
import ServicesImg6 from "../../Assets/images/services-img/email-marketing.png";
import DentistImg from '../../Assets/girl-dentist.webp'
import { NavLink } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
const DigitalMarketingDentist = () => {
    const history = useHistory();
    const captchaRef = useRef(null);
    const [selectedDate, setSelectedDate] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [phoneError, setPhoneError] = React.useState("");
    const [business, setBusiness] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [time, setTime] = React.useState("10:00");
    const [timefeild, setTimefeild] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [timezone, setTimezone] = React.useState("");
    const [load, setLoad] = React.useState(false);
    const [captcha, setCaptcha] = React.useState(true);
    const [services, setServices] = useState([]);

    const bottomRef = useRef(null);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    function onChange(value) {
        if (value) {
            setCaptcha(false);
        }
    }
    const selectService = (val) => {
        if (services.includes(val)) {
            var arr = services.filter(value => value != val);
            setServices(arr);
        } else {
            var arr = services.push(val);
        }
    }
    const SubmitServicedetails = (e) => {
        e.preventDefault();
        captchaRef.current.reset();
        if (phone.length > 7) {
            const data = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                phone: phone.length ? `+${phone}` : "",
                website: website,
                company_name: business,
                services: services
            };
            setLoad(true);
            axios
                .post(`${baseURL}/service/send_service_request`, data, {
                    headers: postHeader,
                })
                .then((res) => {
                    if (res.data.status == 1) {
                        swal(res.data.message, { icon: "success" });
                        setFirstname("");
                        setLastname("");
                        setPhone("");
                        setEmail("");
                        setMessage("");
                        setTime("10:00");
                        setTimefeild("");
                        setTimezone("");
                        setBusiness("");

                        setLoad(false);
                        history.push("/digital-marketing/dentist");
                    } else {
                        swal(res.data.message, { icon: "warning" });
                        setLoad(false);
                    }
                    setWebsite("");
                })
                .catch((error) => {
                    if (error) {
                        swal("something went wrong", { icon: "error" });
                        setLoad(false);
                    }
                });
        }
        else {
            setPhoneError('Please Enter Valid Number');
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Web & Digital Services For Jewelers | Belgium WebNet
                </title>
                <meta
                    name="description"
                    content="Our web and digital marketing services can help jewelry businesses reach out to their potential target customers. Book an appointment with our experts now!"
                ></meta>
                <meta name="keywords" content="web service, digital marketing service "></meta>
            </Helmet>
            <section className="bw_services_section_main">
                <div className="bw_all_banner bw_banner_pricing">
                    <Container className="bw_custome_container">

                        <div className="bw_all_banner bw_wdd_banner">
                            <Container className="bw_custome_container">
                                <Row>
                                    <Col>
                                        {/* <h1 className="text-center text-white text-capitalize">
                                                Search Engine Optimization
                                            </h1> */}
                                        <h1 className="bw_banner_heading">Digital Marketing for Dentist Office</h1>
                                        <ul className=" d-flex bw_Contact_link text-center pl-0">
                                            <li>
                                                <NavLink to="/" className="text-white">
                                                    Home
                                                </NavLink>
                                            </li>
                                            <li>
                                                <BsChevronRight className="text-white" />
                                            </li>
                                            <li>
                                                <NavLink to="/digital-marketing" className="text-white">
                                                    Digital Marketing
                                                </NavLink>
                                            </li>
                                            <li>
                                                <BsChevronRight className="text-white" />
                                            </li>
                                            <li>
                                                <NavLink to="/digital-marketing/dentist" className="text-white">
                                                    Dentist
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Container>
                </div>
                <div className="bw_sercices_image_section">
                    <Container className="bw_custome_container container ">
                        <Row className="w-100 m-auto">
                            <Col sm={12} md={6} lg={6} className="bw_sevices_left_text_box">
                                <div
                                    className="text-left bw_servics_textyyy"
                                >
                                    <p className="bw_banner_sub_heading">
                                        Transform your dental practices with individualized strategies for attracting a patient base. Belgium WebNet offers the <strong> best digital marketing for dentists, </strong> merging innovative techniques with proven methods to encourage practice growth and success.
                                    </p>
                                    <p className="bw_banner_sub_heading">
                                        Ready to take your dental practice to the next level? Our team can help you attract more patients and grow your business. Book a consultation with Belgium WebNet today to see how we can make a difference for you.
                                    </p>

                                    <button className="btn btn-primary mt-4 ml-3" onClick={scrollToBottom}>
                                        Book An Appointment
                                    </button>
                                    {/* <p><span onClick={() => history.push("/contacts")}>Contact us</span> today to explore how we can elevate your online presence.</p> */}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6} className="bw_sevices_right_text_box bw_img_align_midal d-flex justify-content-center align-items-center">
                                <LazyLoadImage
                                    src={DentistImg}
                                    alt="Dentist  Image"
                                    className="img-fluid"
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main bw_service_branding_main my-5">
                    <Container className="bw_custome_container">
                        <Row>
                            <Col lg={12} xs={12}>
                                <div className="bw_new_branding_main_title text-center">
                                    <h2 className="pb-0 mb-3">  Why Choose Belgium Webnet As  <span className="text-blue">Your Dental Digital Marketing Agency  </span>  </h2>
                                    <p className="mx-w-900 mx-auto mb-4 mb-lg-5">
                                        Belgium WebNet is a known  <strong>digital marketing agency for dentists.</strong> We craft personalized solutions that deliver results. Here is why we are the perfect match for your practice.
                                    </p>
                                </div>

                                <Row className="mb-4 dentist_card_design">
                                    <Col lg={4} sm={6} xs={12}>
                                        <Card className="bw_new_branding_box h-100">
                                            <Card.Body>
                                                {/* <div className="bw_new_branding_box_icon">
                                            <Link to="/digital-marketing/seo">
                                                {" "}
                                                <LazyLoadImage src={ServicesImg5} alt="Brand Image" />
                                            </Link>
                                        </div> */}
                                                <h3>
                                                    {/* <Link to="/digital-marketing/seo" className="colorBlack"> */}
                                                    <span className="colorBlack">  Expertise in Dental Marketing </span>

                                                    {/* </Link> */}
                                                </h3>
                                                <p className="mb-0">
                                                    Our team understands the challenges and develops tailored solutions to tackle them within the dentistry industry. Whether your needs include improving online capabilities or driving patient acquisition, we have something to suit them.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>

                                        <Card className="bw_new_branding_box h-100">
                                            <Card.Body>
                                                {/* <div className="bw_new_branding_box_icon">
                                        <Link to="/web-development-design">
                                            <LazyLoadImage src={ServicesImg1} alt="Brand Image" />
                                        </Link>
                                    </div> */}
                                                <h2>
                                                    {/* <Link to="/web-development-design" className="colorBlack"> */}
                                                    <span className="colorBlack"> Proven Results </span>

                                                    {/* </Link> */}
                                                </h2>
                                                <p className="mb-0">
                                                    Attracting new patients and ensuring better visibility is achieved through well-researched and calculated data-driven campaigns that are measurable. Every penny spent counts and leads to results that can be traced to the outcome of practice growth.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={4} sm={6} xs={12}>
                                        <Card className="bw_new_branding_box h-100">
                                            <Card.Body>
                                                {/* <div className="bw_new_branding_box_icon">
                                            <Link to="/social-media-marketing">
                                                {" "}
                                                <LazyLoadImage src={ServicesImg4} alt="Brand Image" />{" "}
                                            </Link>
                                        </div> */}
                                                <h2>
                                                    {/* <Link to="/social-media-marketing" className="colorBlack"> */}
                                                    <span className="colorBlack"> Customized Strategies
                                                    </span>
                                                    {/* </Link>{" "} */}
                                                </h2>
                                                <p className="mb-0">
                                                    Every dental practice pursues different objectives, which we can echo through our unique dental digital marketing services. With plans designed to meet your needs, local outreach to strategies for online domination, provide end-to-end marketing solutions.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                    </Container>
                </section>

                <section className=" bw_new_branding_desktop hm_branding_box_main bw_service_branding_main my-5">
                    <Container className="bw_custome_container">
                        <Row>
                            <Col lg={12} xs={12}>
                                <div className="bw_new_branding_main_title text-center">
                                    <h2 className="pb-0 mb-3"> Our Digital Marketing Services for Dentists  </h2>
                                    <p className="mx-w-900 mx-auto mb-4 mb-lg-5">
                                        Custom digital marketing for dentists helps your practice grow. Our strategies boost visibility, attract quality patients, and enhance your online presence for success in a competitive market.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} sm={6} xs={12}>
                                <div className="bw_new_branding_box">
                                    <div className="bw_new_branding_box_icon">
                                        <Link to="/digital-marketing/seo">
                                            {" "}
                                            <LazyLoadImage src={ServicesImg5} alt="Brand Image" />
                                        </Link>
                                    </div>
                                    <h3>
                                        <Link to="/digital-marketing/seo" className="colorBlack">
                                            {" "}
                                            <span className="colorBlack">  Search Engine <br></br> Optimization{" "} </span>
                                        </Link>
                                    </h3>
                                    <p>
                                        Enhance your dental website's SEO to rank higher in search results for patients seeking dental services.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>

                                <div className="bw_new_branding_box ">
                                    <div className="bw_new_branding_box_icon">
                                        <Link to="/pages/ppc">
                                            <LazyLoadImage src={GoogleImg} alt="Brand Image" />
                                        </Link>
                                    </div>
                                    <h2>
                                        <Link to="/pages/ppc" className="colorBlack">
                                            {" "}
                                            <span className="colorBlack">  Pay Per Click <br /> Advertising (Google Ads)</span>
                                        </Link>
                                    </h2>
                                    <p>
                                        Use relevant keywords in PPC campaigns for quick results. Conversion-focused ads attract qualified leads and boost ROI.
                                    </p>
                                </div>
                            </Col>

                            <Col lg={4} sm={6} xs={12}>
                                <div className="bw_new_branding_box">
                                    <div className="bw_new_branding_box_icon">
                                        <Link to="/social-media-marketing">
                                            {" "}
                                            <LazyLoadImage src={ServicesImg4} alt="Brand Image" />{" "}
                                        </Link>
                                    </div>
                                    <h2>
                                        <Link to="/social-media-marketing" className="colorBlack">
                                            {" "}
                                            <span className="colorBlack">   Social Media <br />Marketing{" "} </span>
                                        </Link>{" "}
                                    </h2>
                                    <p>
                                        Improve relations with your customers with strategies like
                                        SMO, CRO, Content Marketing, and much more.
                                    </p>
                                </div>
                            </Col>

                            <Col lg={4} sm={6} xs={12}>
                                <div className="bw_new_branding_box">
                                    <div className="bw_new_branding_box_icon">
                                        <LazyLoadImage src={SeoImg} alt="Brand Image" />
                                    </div>
                                    <h2>
                                        {/* <Link to="/pages/shopify-development" className="colorBlack"> */}
                                        <span className="colorBlack">Local SEO <br></br> Service</span>
                                        {/* </Link> */}
                                    </h2>
                                    <p>
                                        Let your dental clinic feature prominently in local searches and Google Maps. Our local strategies guarantee that your clinic will be the number one option for nearby patients.
                                    </p>
                                </div>
                            </Col>

                            <Col lg={4} sm={6} xs={12}>
                                <div className="bw_new_branding_box">
                                    <div className="bw_new_branding_box_icon">
                                        <Link to="/web-development-design" className="colorBlack">
                                            <LazyLoadImage src={WebDesignImg} alt="Brand Image" />
                                        </Link>
                                    </div>
                                    <h2>
                                        <Link to="/web-development-design" className="colorBlack">
                                            <span className="colorBlack">   Web Design and
                                                <br></br>Development </span>
                                        </Link>
                                    </h2>
                                    <p>
                                        We design up-to-date, user-friendly websites according to your dental practice. Our designs are focused on functionality and mobile optimization.
                                    </p>
                                </div>
                            </Col>

                            <Col lg={4} sm={6} xs={12}>
                                <div className="bw_new_branding_box">
                                    <div className="bw_new_branding_box_icon">
                                        {/* <Link to="/pages/ppc"> */}
                                        {" "}
                                        <LazyLoadImage src={MarketingImg} alt="Brand Image" />{" "}
                                        {/* </Link> */}
                                    </div>
                                    <h2>
                                        {/* <Link to="/pages/ppc" className="colorBlack"> */}
                                        <span className="colorBlack">   Content   <br></br>Marketing{" "}</span>
                                        {/* </Link>{" "} */}
                                    </h2>
                                    <p>
                                        Engage your audience with valuable content, such as blogs and videos, to position your practice as an industry expert and trusted provider.

                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main bw_service_branding_main my-4">
                    <Container className="bw_custome_container">
                        <Row>
                            <Col lg={12} xs={12}>
                                <div className="bw_new_branding_main_title text-center">
                                    <h2 className="pb-0 mb-3"> Our Process  </h2>
                                    <p className="mx-w-900 mx-auto mb-4 mb-lg-5">
                                        Custom digital marketing for dentists helps your practice grow. Our strategies boost visibility, attract quality patients, and enhance your online presence for success in a competitive market.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-4 our_process_container">
                            <Col lg={4} sm={6} xs={12}>
                                <Card className="bw_new_branding_box h-100">
                                    <Card.Body>
                                        {/* <div className="bw_new_branding_box_icon">
                                            <Link to="/digital-marketing/seo">
                                                {" "}
                                                <LazyLoadImage src={ServicesImg5} alt="Brand Image" />
                                            </Link>
                                        </div> */}
                                        <h3>
                                            {/* <Link to="/digital-marketing/seo" className="colorBlack"> */}
                                            <span className="colorBlack">  Complimentary Audit </span>

                                            {/* </Link> */}
                                        </h3>
                                        <p className="mb-0">
                                            You will receive a complimentary audit that checks your existing online presence and assesses its strengths and areas for improvement.
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>

                                <Card className="bw_new_branding_box h-100">
                                    <Card.Body>
                                        {/* <div className="bw_new_branding_box_icon">
                                        <Link to="/web-development-design">
                                            <LazyLoadImage src={ServicesImg1} alt="Brand Image" />
                                        </Link>
                                    </div> */}
                                        <h2>
                                            {/* <Link to="/web-development-design" className="colorBlack"> */}
                                            <span className="colorBlack"> Digital Strategy Call </span>

                                            {/* </Link> */}
                                        </h2>
                                        <p className="mb-0">
                                            We then create a digital strategy call to discuss the specific goals and challenges and make a plan for a particular practice.
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg={4} sm={6} xs={12}>
                                <Card className="bw_new_branding_box h-100">
                                    <Card.Body>
                                        {/* <div className="bw_new_branding_box_icon">
                                            <Link to="/social-media-marketing">
                                                {" "}
                                                <LazyLoadImage src={ServicesImg4} alt="Brand Image" />{" "}
                                            </Link>
                                        </div> */}
                                        <h2>
                                            {/* <Link to="/social-media-marketing" className="colorBlack"> */}
                                            <span className="colorBlack">  Results-Driven Action Plan
                                            </span>
                                            {/* </Link>{" "} */}
                                        </h2>
                                        <p className="mb-0">
                                            Once a digital strategy is decided action steps leading to measurable change in digital performance levels will now be implemented.
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </section>



                <div className="bw_services_form_section" ref={bottomRef}>
                    <Container>
                        <Row className="w-100 m-auto">
                            <Col>
                                <div
                                    className="bw_contact_form_outer_box"
                                >
                                    <h2>
                                        <span className="text-blue"> Claim Your  Complimentary Audit  and</span>    <span className="text-blue"> Personalized Digital Marketing Proposal </span>
                                    </h2>
                                    <div className="bw_contact_form_inner_box">
                                        <Form
                                            className="bw_contact_us_form"
                                            onSubmit={SubmitServicedetails}

                                        >
                                            <Row>
                                                <Col sm={12} md={12} lg={12}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Label>Company Name*</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Company Name"
                                                            value={business}
                                                            onChange={(e) => setBusiness(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6} lg={6}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="First Name"
                                                            value={firstname}
                                                            onChange={(e) => setFirstname(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6} lg={6}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Last Name"
                                                            value={lastname}
                                                            onChange={(e) => setLastname(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={12} lg={12}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Label>Email Address</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Email Id"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6} lg={6}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Label>Phone Number</Form.Label>
                                                        <PhoneInput
                                                            disableSearchIcon={true}
                                                            enableSearch={true}
                                                            country={"us"}
                                                            value={phone}
                                                            onChange={setPhone}
                                                            // onlyCountries={[
                                                            //     "us",
                                                            //     "cn",
                                                            //     "in",
                                                            //     "au",
                                                            //     "jp",
                                                            //     "hk",
                                                            //     "kw",
                                                            //     "gb",
                                                            //     "es",
                                                            // ]}
                                                            inputProps={{
                                                                name: "phone",
                                                                required: true,
                                                                autoFocus: false,
                                                            }}
                                                            isValid={(value, country) => {
                                                                console.log('value', value.length);
                                                                if (value.length < 10) {
                                                                    return "please enter valid number";
                                                                } else {
                                                                    return true;
                                                                }
                                                            }}

                                                        />
                                                        {!phoneError.length ? '' : <p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={12} md={6} lg={6}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Label>Website</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Website"
                                                            value={website}
                                                            onChange={(e) => setWebsite(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={12} md={12} lg={12}>

                                                    <Form.Label>Services</Form.Label>
                                                    <FormGroup aria-label="position" row className="mb-3">
                                                        <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                                                            <FormControlLabel
                                                                value="end"
                                                                label="Website Design & Development"
                                                                control={<Checkbox />}
                                                                labelPlacement="end"
                                                                className="bwn_consultation_form_checkbox"
                                                                onChange={() => selectService("Website Design & Development")}
                                                            />
                                                        </Col>


                                                        <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox />}
                                                                label="Shopify Development"
                                                                labelPlacement="end"
                                                                className="bwn_consultation_form_checkbox"
                                                                onChange={() => selectService("Shopify Development")}
                                                            />
                                                        </Col>

                                                        <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox />}
                                                                label="Social Media Marketing"
                                                                labelPlacement="end"
                                                                className="bwn_consultation_form_checkbox"
                                                                onChange={() => selectService("Social Media Marketing")}
                                                            />
                                                        </Col>

                                                        <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox />}
                                                                label="Search Engine Optimization"
                                                                labelPlacement="end"
                                                                className="bwn_consultation_form_checkbox"
                                                                onChange={() => selectService("Search Engine Optimization")}
                                                            />
                                                        </Col>

                                                        <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox />}
                                                                label="Email Marketing"
                                                                labelPlacement="end"
                                                                className="bwn_consultation_form_checkbox"
                                                                onChange={() => selectService("Email Marketing")}
                                                            />
                                                        </Col>

                                                        <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox />}
                                                                label="Paid Marketing"
                                                                labelPlacement="end"
                                                                className="bwn_consultation_form_checkbox"
                                                                onChange={() => selectService("Paid Marketing")}
                                                            />
                                                        </Col>
                                                    </FormGroup>

                                                </Col>
                                                <Col sm={12} md={12} lg={12}>
                                                    <div className="rcs_cap_main">
                                                        <ReCAPTCHA
                                                            className="rcs_g-recaptcha"
                                                            ref={captchaRef}
                                                            sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <button disabled={load || captcha ? true : false}>
                                                    {load ? (
                                                        <div className="bw_loader_style">
                                                            <Loader
                                                                type="spinner-default"
                                                                bgColor={"#FFFFFF"}
                                                                color={"#FFFFFF"}
                                                                size={20}
                                                            />
                                                        </div>
                                                    ) : (
                                                        "submit"
                                                    )}
                                                </button>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    );
};

export default React.memo(DigitalMarketingDentist);
