import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import "../../Assets/css/diamondJewelryDesigns.css";
import { FaPlay } from "react-icons/fa";
import Theme1 from "../../Assets/images/diamond-jewelry-designs/theme1.webp";
import Theme2 from "../../Assets/images/diamond-jewelry-designs/theme2.webp";
import Theme3 from "../../Assets/images/diamond-jewelry-designs/theme3.webp";
import Theme4 from "../../Assets/images/diamond-jewelry-designs/theme4.webp";
import Theme5 from "../../Assets/images/diamond-jewelry-designs/theme5.webp";
import ThemeDesign1 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme1.webp";
import ThemeDesign3 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme3.webp";
import ThemeDesign4 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme4.webp";
import ThemeDesign7 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme7.webp";
import ThemeDesign8 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme8.webp";
import ThemeDesign10 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme10.webp";
import ThemeDesign12 from "../../Assets/images/diamond-jewelry-designs/themes-img/Havana-theme.webp";
import ThemeDesign14 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme14.webp";
import ThemeDesign15 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme15.webp";
import ThemeDesign17 from "../../Assets/images/diamond-jewelry-designs/themes-img/theme17.webp";
import OnlineStore from "../../Assets/images/diamond-jewelry-designs/online-store.webp";
import OwnerImg1 from "../../Assets/images/diamond-jewelry-designs/social-manag-img/sm.png";
import OwnerImg2 from "../../Assets/images/diamond-jewelry-designs/social-manag-img/cbooster.png";
import OwnerImg3 from "../../Assets/images/diamond-jewelry-designs/social-manag-img/ringbilder.png";
import OwnerImg4 from "../../Assets/images/diamond-jewelry-designs/social-manag-img/webm.png";
import DesignSlide1 from "../../Assets/images/diamond-jewelry-designs/collection-img/braslate.webp";
import DesignSlide2 from "../../Assets/images/diamond-jewelry-designs/collection-img/earing.webp";
import DesignSlide3 from "../../Assets/images/diamond-jewelry-designs/collection-img/necklaces.webp";
import DesignSlide4 from "../../Assets/images/diamond-jewelry-designs/collection-img/pendant.webp";
import DesignSlide5 from "../../Assets/images/diamond-jewelry-designs/collection-img/ring.webp";
import ResponsiveDeviceImg from "../../Assets/images/diamond-jewelry-designs/responsive-device.webp";
import { NavLink } from "react-router-dom";
import SlideLeftIcon from "../../Assets/images/diamond-jewelry-designs/arrow-left-slide.png";
import SlideRightIcon from "../../Assets/images/diamond-jewelry-designs/arrow-right-slide.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { VscDeviceMobile } from "react-icons/vsc";
import { IoIosLaptop, IoIosDesktop } from "react-icons/io";
import { FiTablet } from "react-icons/fi";
import AdminImg1 from "../../Assets/images/diamond-jewelry-designs/admin1.webp";
import AdminImg2 from "../../Assets/images/diamond-jewelry-designs/admin2.webp";
import AdminImg3 from "../../Assets/images/diamond-jewelry-designs/admin3.webp";
import AdminDesktop from "../../Assets/images/diamond-jewelry-designs/desktop1.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LeftIcon from "../../Assets/images/home/left_icon.png";
import RightIcon from "../../Assets/images/home/right_arrow.png";


export const DesignThemeData = [
  {
    name: "newyork",
    image: `${ThemeDesign10}`,
    // url: "https://newyork.demobw.com/",
    url: "/diamond-jewelry-designs/newyork",
  },

  {
    name: "macau",
    image: `${ThemeDesign3}`,
    // url: "https://macau.demobw.com/",
    url: "/diamond-jewelry-designs/macau",
  },
  {
    name: "london",
    image: `${ThemeDesign4}`,
    // url: "https://london.demobw.com/",
    url: "/diamond-jewelry-designs/london",
  },
  {
    name: "paris",
    image: `${ThemeDesign14}`,
    // url: "https://paris.demobw.com/",
    url: "/diamond-jewelry-designs/paris",
  },

  {
    name: "dubai",
    image: `${ThemeDesign15}`,
    url: "https://dubai.demobw.com/",
  },

  {
    name: "venice",
    image: `${ThemeDesign7}`,
    // url: "https://venice.demobw.com/",
    url: "/diamond-jewelry-designs/venice",
  },
  {
    name: "rome",
    image: `${ThemeDesign17}`,
    url: "https://rome.demobw.com/",
  },

  {
    name: "tokyo",
    image: `${ThemeDesign8}`,
    // url: "https://tokyo.demobw.com/",
    url: "/diamond-jewelry-designs/tokyo",
  },
  {
    name: "miami",
    image: `${ThemeDesign1}`,
    // url: "https://miami.demobw.com/",
    url: "/diamond-jewelry-designs/miami",
  },

  {
    name: "havana",
    image: `${ThemeDesign12}`,
    url: "https://havana.demobw.com/",
  },
];

const DiamondJewelryDesigns = () => {
  const renderTooltip1 = (props) => (
    <Tooltip id="button-tooltip" {...props} className="bg-light text-dark">
      Mobile
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props} className="bg-light text-dark">
      Tablet
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props} className="bg-light text-dark">
      Laptop
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip id="button-tooltip" {...props} className="bg-light text-dark">
      Desktop
    </Tooltip>
  );




  const projectLayouts = {
    className: "center",
    infinite: false,
    dots: true,
    Nav: true,
    slidesToShow: 2.8,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    nextArrow: <img src={SlideLeftIcon} alt="Slider Left Icon" />,
    prevArrow: <img src={SlideRightIcon} alt="Slider Right Icon" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const layoutSliderMobileview = {
    className: "center",
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    Nav: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <LazyLoadImage src={RightIcon} alt='Left Icon' />,
    prevArrow: <LazyLoadImage src={LeftIcon} alt='Right Icon' />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const OwnerSliderMobileview = {
    className: "center",
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    Nav: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "200px",
          slidesToShow: 1,
          speed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  const CollectionData = [DesignSlide1, DesignSlide2, DesignSlide3, DesignSlide4, DesignSlide5]



  const ref = useRef(null);
  // const [loadmore,setLoadmore] = React.useState(6)
  const handleclick = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom Templates | Web Design Company For Jewelers</title>
        <meta
          name="description"
          content="Engage customers and drive traffic to your jewelry store with a tailor-made website from Belgium WebNet, a web design company for jewelers. Book an appointment!"
        ></meta>
        <meta name="keywords" content="web design company for jewelers"></meta>
      </Helmet>
      <section className="bw_djdesign_section">
        {/* Design banner section start */}
        <div className="bw_all_banner bw_djdesign_banner">
          <Container fluid className="px-0">
            <Row className="w-100 m-auto">
              <Col xs={12} sm={12} md={3} lg={3} className="pl-0">
                <div className="bw_djdesign_leftimg">
                  <LazyLoadImage
                    src={Theme1}
                    className="float-left img-fluid"
                    alt="Theme Image"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="bw_djdesign_banner_center">
                  <h6>HANDCRAFTED EXPERIENCE</h6>
                  <h2>
                    Create your own wonderland with our magnitude of themes!
                  </h2>
                  <div className="bw_djdesign_banner_btn_box">
                    <button
                      onClick={handleclick}
                      className="bw_djdesign_btn_explore"
                    >
                      <FaPlay />
                      EXPLORE THEMES
                    </button>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} className="pr-0">
                <div className="bw_djdesign_leftimg">
                  <LazyLoadImage
                    src={Theme2}
                    className="float-right img-fluid"
                    alt="Theme Image"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_djdesign_threeimg_section position-relative">
          <Container className="bw_custome_container_banner">
            <Row>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="bw_banner_theme_img1">
                  <LazyLoadImage
                    src={Theme3}
                    alt="Theme4 Image"
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="bw_banner_theme_img2">
                  <LazyLoadImage
                    src={Theme4}
                    alt="Theme4 Image"
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="bw_banner_theme_img3">
                  <LazyLoadImage
                    src={Theme5}
                    alt="Theme4 Image"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Design banner section end */}
        {/* theme Design start */}
        <div className="bw_djdesign_newsite" ref={ref}>
          <Container className="bw_custome_container_banner">
            <Row className="w-100 m-auto">
              <Col>
                <h2 className="text-center">
                  A new site for your agency? It’s here.
                </h2>
                <p className="text-center">Great selection of complete & flexible agency demos.</p>
              </Col>
            </Row>
            <Row className="w-100 m-auto bw_djdesign_layout_desktop_view">
              {DesignThemeData.map((res) => <Col
                sm={12}
                md={6}
                lg={4}
                className="bwn_djdesign_them_img_outerbox"
              >
                <NavLink to="#" onClick={() => { window.open(res.url, "_blank") }} className="bw_djdesign_theme_img_box">
                  <LazyLoadImage src={res.image} alt="Theme Image" />
                  <h6 className="text-center"> {res.name} </h6>
                </NavLink>
                <NavLink to="#" onClick={() => { window.open(res.url, "_blank") }} className="bw_djdesign_view_more_btn">
                  Live Preview
                </NavLink>
              </Col>)}
            </Row>
            {/* <Row className="bw_loade_more_design_btn_desktop_view">
              <Col>
                   <div className="bw_btb_border_black text-center m-auto pb-2 pt-4">
                      {loadmore==6 ? <button type="button" className="bw_custom_btn text-uppercasee bw_btn_load_more btn btn-primary" onClick={()=>setLoadmore(DesignThemeData.length)}>LOAD MORE</button>
                      : <button type="button" className="bw_custom_btn text-uppercasee bw_btn_load_more btn btn-primary" onClick={()=>{setLoadmore(6);window.scrollTo(0, 1000 );}}>LESS MORE</button>}
                    </div>
              </Col>
            </Row> */}
            {/* Mobile View of themes start */}
            <Row className="bw_djdesign_new_site_mobile_view">
              <Col>
                <Slider {...layoutSliderMobileview}>
                  {DesignThemeData.map((res) => (
                    <div>
                      <NavLink
                        to="#"
                        className="bw_djdesign_theme_img_box"
                        onClick={() => { window.open(res.url, "_blank") }}

                      >
                        <LazyLoadImage src={res.image} alt="Theme Image" />
                        <h6 className="text-center">{res.name}</h6>
                      </NavLink>
                      <NavLink to="#" className="bw_djdesign_view_more_btn" onClick={() => { window.open(res.url, "_blank") }}>
                        View More
                      </NavLink>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
            {/* Mobile View of themes end */}
          </Container>
        </div>
        {/* theme Design ends */}
        {/* Online Store Section start */}
        <div className="bw_djdesign_online_store_section">
          <Container className="bw_custome_container_banner">
            <Row className="w-100 m-auto">
              <Col sm={12} md={5} lg={5}>
                <div>
                  <LazyLoadImage
                    src={OnlineStore}
                    className="img-fluid float-right"
                    alt="Online Store Image"
                  />
                </div>
              </Col>
              <Col
                sm={12}
                md={7}
                lg={7}
                className="bw_djdesign_onlinestore_text"
              >
                <div>
                  <div
                    className="bw_djdesign_onlinestore_text_inner"
                  >
                    <h2>
                      Give your customers a chance to build their dream ring!
                    </h2>
                    <h6>
                      A feature that brings to life extraordinary designs and
                      creations
                    </h6>
                    <p>
                      Our ring builder enables you to compile different styles
                      into one to create a ring that your customer finds ideal.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Online Store Section end */}
        <div className="bw_djdesign_website_owner_section">
          <Container className="bw_custome_container_banner">
            <Row className="w-100 m-auto ">
              <Col>
                <h2 className="text-center">
                  150 customers 100+ Website Owners<br></br> Trust
                  <span> Belgium WebNet </span>
                </h2>
              </Col>
            </Row>
            <Row className="bw_djdesign_website_owner_section_Desktop_view">
              <Col sm={12} md={6} lg={3}>
                <div
                  className="bw_djd_onner_inner_box"
                >
                  <LazyLoadImage
                    src={OwnerImg1}
                    alt="Owner Image"
                    className="img-fluid"
                  />
                  <h6>Social Media Synchronisation</h6>
                  <p>
                    Drive more traffic to your social media profiles by
                    synchronizing your website with varied social platforms.
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div
                  className="bw_djd_onner_inner_box"
                >
                  <LazyLoadImage
                    src={OwnerImg2}
                    alt="Owner Image"
                    className="img-fluid"
                  />
                  <h6>Conversion Booster</h6>
                  <p>
                    Get maximum leads by interacting with your website visitors
                    and navigating their history to exhibit relevant
                    suggestions.
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div
                  className="bw_djd_onner_inner_box"
                >
                  <LazyLoadImage
                    src={OwnerImg3}
                    alt="Owner Image"
                    className="img-fluid"
                  />
                  <h6>Ring Builder</h6>
                  <p>
                    Give your audience seamless first-hand customization by
                    allowing them to pick a ring, find a diamond, and checkout.
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div
                  className="bw_djd_onner_inner_box"
                >
                  <LazyLoadImage
                    src={OwnerImg4}
                    alt="Owner Image"
                    className="img-fluid"
                  />
                  <h6>Expert Website Management</h6>
                  <p>
                    Manage your products, content, orders, etc with the Admin
                    Panel as your website manager.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="bw_djdesign_website_owner_section_movile_view">
              <Col>
                <Slider {...OwnerSliderMobileview}>
                  <div className="bw_djd_onner_outbox">
                    <div className="bw_djd_onner_inner_box">
                      <LazyLoadImage
                        src={OwnerImg1}
                        alt="Owner Image"
                        className="img-fluid m-auto"
                      />
                      <h6>Social Media Synchronisation</h6>
                      <p>
                        Drive more traffic to your social media profiles by synchronizing your website with varied social platforms.
                      </p>
                    </div>
                  </div>
                  <div className="bw_djd_onner_outbox">
                    <div className="bw_djd_onner_inner_box">
                      <LazyLoadImage
                        src={OwnerImg2}
                        alt="Owner Image"
                        className="img-fluid m-auto"
                      />
                      <h6>Conversion Booster</h6>
                      <p>
                        Get maximum leads by interacting with your website visitors and navigating their history to exhibit relevant suggestions.
                      </p>
                    </div>
                  </div>
                  <div className="bw_djd_onner_outbox">
                    <div className="bw_djd_onner_inner_box">
                      <LazyLoadImage
                        src={OwnerImg3}
                        alt="Owner Image"
                        className="img-fluid m-auto"
                      />
                      <h6>Ring Builder</h6>
                      <p>
                        Give your audience seamless first-hand customization by allowing them to pick a ring, find a diamond, and checkout.
                      </p>
                    </div>
                  </div>
                  <div className="bw_djd_onner_outbox">
                    <div className="bw_djd_onner_inner_box">
                      <LazyLoadImage
                        src={OwnerImg3}
                        alt="Owner Image"
                        className="img-fluid m-auto"
                      />
                      <h6>Expert Website Management</h6>
                      <p>
                        Manage your products, content, orders, etc with the Admin Panel as your website manager.
                      </p>
                    </div>
                  </div>
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Multiple layout section start */}
        <div className="bw_djdesign_slider_section">
          <Container className="bw_custome_container_banner">
            <Row>
              <Col
                className="bw_djdesign_slider_heading"
              >
                <h6>INNER PAGES</h6>
                <h2>
                  Great collection of<br></br> multiuse layouts
                </h2>
              </Col>
            </Row>
          </Container>
          <Container fluid className="pr-0 bw_djd_slider_padding">
            <Row className="w-100 m-auto">
              <Col
                className="pr-0 bw_djd_layouts_slider"
              >
                <Slider {...projectLayouts}>
                  {CollectionData.map((res) =>
                    <Col>
                      <div className="bw_project_slider_item">
                        <LazyLoadImage
                          src={res}
                          alt="Design theme Image"
                        />
                      </div>
                    </Col>
                  )}
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Multiple layout section end */}
        {/* Responsive Device section Start */}
        <div className="bw_djdesign_responsive_devices_section">
          <Container className="bw_custome_container_banner">
            <Row className="w-100 m-auto">
              <Col>
                <div
                  className="position-relative text-center"
                >
                  <LazyLoadImage
                    src={ResponsiveDeviceImg}
                    className="img-fluid"
                    alt="Responsive Devaice Image"
                  />
                  <div
                    className="bw_djdesign_pixle_box"
                  >
                    <h4>A Website that is compatible with all your devices</h4>
                    <p>
                      A responsive layout ensures that your audience can access
                      your website from anywhere and shop with you at their own
                      ease.
                    </p>
                    <div className="d-flex justify-content-center">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip1}
                      >
                        <div className="bw_djd_res_icon_box">
                          <VscDeviceMobile />
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip2}
                      >
                        <div className="bw_djd_res_icon_box">
                          <FiTablet />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip3}
                      >
                        <div className="bw_djd_res_icon_box">
                          <IoIosLaptop />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip4}
                      >
                        <div className="bw_djd_res_icon_box">
                          <IoIosDesktop />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Responsive Device section end */}
        {/* Admin Section start */}
        <div className="bwn_djdesign_admin_section">
          <Container className="bw_custome_container_banner">
            <Row className="w-100 m-auto">
              <Col
                className="bwn_djdesign_admin_heading"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2>Fully Customizable Admin With Multiple Options</h2>
                <p>
                  We create an admin panel that resonates with your style of
                  working. Fully customizable to help create a shop, add or
                  delete products, alter categories, tag and track order and
                  extend setting options.
                </p>
              </Col>
            </Row>

            <Row className="w-100 m-auto">
              <Col sm={12} md={12} lg={4}>
                <div className="d-flex bwn_djdesign_admin_icon">
                  <LazyLoadImage
                    src={AdminImg1}
                    alt="Admin Icon Image"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  />
                  <div
                    className="bw_djd_admin_text"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <h6>Highly Customizable </h6>
                    <p>
                      We offer you full liberty to make things work your way.
                      The admin panel we offer is fully customizable and can be
                      moulded as per your needs.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <div className="d-flex bwn_djdesign_admin_icon">
                  <LazyLoadImage
                    src={AdminImg2}
                    alt="Admin Icon Image"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  />
                  <div
                    className="bw_djd_admin_text"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <h6>Easy Management</h6>
                    <p>
                      Easy management is one of the key features of our admin
                      panel. We ensure that you get to work things out your way,
                      with necessary alterations and easy accessibility.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <div className="d-flex bwn_djdesign_admin_icon bw_design_admin_border_right border-0 border-bottom-0 ">
                  <LazyLoadImage
                    src={AdminImg3}
                    alt="Admin Icon Image"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  />
                  <div
                    className="bw_djd_admin_text"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <h6>Easy Tracking</h6>
                    <p>
                      The Admin panel allows you to access and track orders, add
                      categories, initiate refunds and payments
                      as per your inventory. Make buying less of a hassle for
                      yourself and your customers.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col
                className="text-center bw_admin_desktop_img"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <LazyLoadImage
                  src={AdminDesktop}
                  alt="Admin Desktop Img"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* Admin Section end */}
      </section>
    </>
  );
};

export default React.memo(DiamondJewelryDesigns);
