import React, { useRef, useState } from "react";
import "../../Assets/css/services.css";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form } from "react-bootstrap";
import NewBranding from "../../Components/Front/NewBranding.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TogetherImg from "../../Assets/images/home/together-img.jpg";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import ServicesImg1 from "../../Assets/images/services-img/webdev.png";
import ServicesImg2 from "../../Assets/images/services-img/ppc.png";
import ServicesImg3 from "../../Assets/images/services-img/shopify.png";
import ServicesImg4 from "../../Assets/images/services-img/smm.png";
import ServicesImg5 from "../../Assets/images/services-img/seo.png";
import ServicesImg6 from "../../Assets/images/services-img/email-marketing.png";
import ServicesImg7 from "../../Assets/images/services-img/content-marketing.png";
import ServicesImg8 from "../../Assets/images/services-img/local-seo-services.png";
import ServicesImg9 from "../../Assets/images/services-img/e-commerse.png";

import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import Breadcrumbs from '@mui/material/Breadcrumbs';

const Services = () => {
  const history = useHistory();
  const captchaRef = useRef(null);

  const [selectedDate, setSelectedDate] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [business, setBusiness] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [time, setTime] = React.useState("10:00");
  const [timefeild, setTimefeild] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);
  const [services, setServices] = useState([]);
  const [expanded, setExpanded] = React.useState("panel2");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }
  const selectService = (val) => {
    if (services.includes(val)) {
      var arr = services.filter(value => value != val);
      setServices(arr);
    } else {
      var arr = services.push(val);
    }
  }
  const SubmitServicedetails = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    if (phone.length > 7) {
      const data = {
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone: phone.length ? `+${phone}` : "",
        website: website,
        company_name: business,
        services: services
      };
      setLoad(true);
      axios
        .post(`${baseURL}/service/send_service_request`, data, {
          headers: postHeader,
        })
        .then((res) => {
          if (res.data.status == 1) {
            swal(res.data.message, { icon: "success" });
            // setFname("");
            // setLname("");
            // setPhone("");
            // setEmail("");
            // setMessage("");
            // setLoad(false);
            // setSelectedDate("");
            setFirstname("");
            setLastname("");
            setPhone("");
            setEmail("");
            setMessage("");
            setTime("10:00");
            setTimefeild("");
            setTimezone("");
            setBusiness("");
            setWebsite("");
            setLoad(false);
            history.push('./thankyou')
          } else {
            swal(res.data.message, { icon: "warning" });
            setLoad(false);
          }
        })
        .catch((error) => {
          if (error) {
            swal("something went wrong", { icon: "error" });
            setLoad(false);
          }
        });
    }
    else {
      setPhoneError('Please Enter Valid Number');
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Web & Digital Services For Jewelers | Belgium WebNet
        </title>
        <meta
          name="description"
          content="Our web and digital marketing services can help jewelry businesses reach out to their potential target customers. Book an appointment with our experts now!"
        ></meta>
        <meta name="keywords" content="web service, digital marketing service "></meta>
      </Helmet>
      <section className="bw_services_section_main">
        <div className="bw_all_banner bw_banner_pricing">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1 className="bw_banner_heading">Digital Marketing Services</h1>
                <p className="bw_banner_sub_heading">
                  Let’s Build A Better Brand Together
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_sercices_image_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col sm={12} md={12} lg={12}>
                <div>
                  <Breadcrumbs aria-label="breadcrumb" className="bw_bredcrum">
                    <Link underline="hover" color="#000" className="text-dark" to="/" >
                      Home
                    </Link>
                    <Typography sx={{ color: 'text.primary' }} className="mb-0 w-100">Digital Marketing</Typography>
                  </Breadcrumbs>
                </div>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col sm={12} md={6} lg={6} className="bw_sevices_left_text_box">
                <div
                  className="text-left bw_servics_textyyy"
                >
                  <h2>Get Customized Digital Marketing Services for Your Business! </h2>
                  <p>
                    With young minds and fresh ideas, we provide customized digital marketing solutions that help your business grow. We create our services based on what each company needs. Our goal is to help you reach more customers online and achieve success.
                  </p>
                  <p>From website design and development to email marketing, our experts create digital marketing plans that align with your goals. We focus on what matters most to your business for long-term success.</p>
                  <p>Our team uses data-driven insights to help make your brand more visible and attract the right customers. Whether you are a small startup or an established business, we can adapt our solutions to fit your needs.
                  </p>

                  <p>Let’s boost your online presence together. We can help you grow your business using effective digital marketing strategies that produce results.
                  </p>

                  {/* <p><span onClick={() => history.push("/contacts")}>Contact us</span> today to explore how we can elevate your online presence.</p> */}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} className="bw_sevices_right_text_box bw_img_align_midal d-flex justify-content-center align-items-center">
                <LazyLoadImage
                  src={TogetherImg}
                  alt="Services Image"
                  className="img-fluid"
                />
              </Col>

            </Row>
          </Container>
        </div>
        <div className="bg_light_color">
          <Container fluid className="">
            <Row>
              <Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className="bw_sevices_left_text_box  mt-5">
                <div
                  className="text-center bw_servics_textyyy"
                >

                  <h2>Let’s Build A Bigger Brand with Belgium Webnet Online Marketing Services </h2>
                  <h3>Why Choose Belgium Webnet?</h3>
                  <p>
                    Belgium Webnet provides digital marketing solutions to grow your online business. Our expert team delivers result-driven strategies through SEO, SMM, and web development services. We focus on your targets, ensuring increased visibility, better engagement, and measurable success for your brand. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


        {/* <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main bw_service_branding_main pt-0">
          <NewBranding />
        </section> */}
        <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main">
          <Container className="bw_custome_container">
            <Row>
              <Col lg={12} xs={12}>
                <div className="bw_new_branding_main_title">
                  {/* <div className="bw_new_branding_main_title_dots">
                  <span> </span> <p>our services</p>
                </div> */}
                  <h2>Let’s Build A Bigger Brand Together</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box ">
                  <div className="bw_new_branding_box_icon">
                    <Link to="/web-development-design">
                      <LazyLoadImage src={ServicesImg1} alt="Brand Image" />
                    </Link>
                  </div>
                  <h2>
                    <Link to="/web-development-design" className="colorBlack">
                      {" "}
                      Website Design <br /> & Development{" "}
                    </Link>
                  </h2>
                  <p>
                    Creating a website is the first step to commencing your online store. Our team ensures  your site is mobile-compatible, easy to navigate, and optimized for performance for all practical considerations in driving results.
                  </p>
                </div>
              </Col>
              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <LazyLoadImage src={ServicesImg3} alt="Brand Image" />
                  </div>
                  <h2>
                    <Link to="/pages/shopify-development" className="colorBlack">
                      Shopify <br></br> Development
                    </Link>
                  </h2>
                  <p>
                    Our core business is creating customized Shopify stores that are user-friendly and designed for conversions. Our team works on making your online store visually appealing, mobile-responsive, and easy to navigate to promote potential growth in your eCommerce business.
                  </p>
                </div>
              </Col>
              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <Link to="/digital-marketing/seo">
                      {" "}
                      <LazyLoadImage src={ServicesImg5} alt="Brand Image" />
                    </Link>
                  </div>
                  <h2>
                    <Link to="/digital-marketing/seo" className="colorBlack">
                      {" "}
                      Search Engine <br></br> Optimization{" "}
                    </Link>
                  </h2>
                  <p>
                    SEO is one of the primary services each business requires. Our team creates SEO strategies that improve your website’s visibility by attracting more potential customers and boosting sales.

                  </p>
                </div>
              </Col>

              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <Link to="/social-media-marketing">
                      {" "}
                      <LazyLoadImage src={ServicesImg4} alt="Brand Image" />{" "}
                    </Link>
                  </div>
                  <h2>
                    <Link to="/social-media-marketing" className="colorBlack">
                      {" "}
                      Social Media <br></br>Marketing{" "}
                    </Link>{" "}
                  </h2>
                  <p>
                    You can create a personal connection by updating your customers about the latest developments in your business. Our experts create campaigns that drive traffic and help you connect with potential customers.

                  </p>
                </div>
              </Col>
              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <LazyLoadImage src={ServicesImg6} alt="Brand Image" />
                  </div>
                  <h2>
                    <Link to="/pages/email-marketing" className="colorBlack">
                      Email <br></br>Marketing
                    </Link>
                  </h2>
                  <p>
                    Notify your customers with emails that convey the launch of your latest collection or offers. Our team develops custom email campaigns that engage your audience and help generate conversions. Whether newsletters or promotional emails, we create content that gets results.
                  </p>
                </div>
              </Col>

              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <Link to="/pages/ppc">
                      {" "}
                      <LazyLoadImage src={ServicesImg2} alt="Brand Image" />{" "}
                    </Link>
                  </div>
                  <h2>
                    <Link to="/pages/ppc" className="colorBlack">
                      Paid Advertisement <br></br>(PPC){" "}
                    </Link>{" "}
                  </h2>
                  <p>
                    Acquire immediate results with targeted ads at optimised
                    budgets to drive valuable traffic for your business.
                  </p>
                </div>
              </Col>
              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <Link to="/pages/ppc">
                      {" "}
                      <LazyLoadImage src={ServicesImg7} alt="Brand Image" />{" "}
                    </Link>
                  </div>
                  <h2>
                    <Link to="#" className="colorBlack">
                      Content Marketing <br></br>Services
                    </Link>{" "}
                  </h2>
                  <p>
                    The content conveys your brand vision to customers. Our team of writers creates engaging and optimized content that helps your customers visualize your product or service, driving an urge to purchase it.
                  </p>
                </div>
              </Col>
              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <Link to="/pages/ppc">
                      {" "}
                      <LazyLoadImage src={ServicesImg8} alt="Brand Image" />{" "}
                    </Link>
                  </div>
                  <h2>
                    <Link to="#" className="colorBlack">
                      Local SEO  <br></br>Services
                    </Link>{" "}
                  </h2>
                  <p>
                    Using local SEO can make your business visible to customers in nearby areas. Our team of SEO experts optimizes your website according to the latest local SEO practices. This allows your local customers to reach your business easily.
                  </p>
                </div>
              </Col>
              <Col lg={4} sm={6} xs={6}>
                <div className="bw_new_branding_box">
                  <div className="bw_new_branding_box_icon">
                    <Link to="#">
                      {" "}
                      <LazyLoadImage src={ServicesImg9} alt="Brand Image" />{" "}
                    </Link>
                  </div>
                  <h2>
                    <Link to="#" className="colorBlack">
                      eCommerce Marketing <br></br>Services
                    </Link>{" "}
                  </h2>
                  <p>
                    E-commerce marketing is essential for increasing sales of your online store. Our team creates strategies that help your products rank at the top, resulting in higher conversions.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="bw_faq_Section mt-5 mb-4">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h2 className="text-center mb-3">
                  FAQ’S
                </h2>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col className="px-md-0">
                <div
                  className="bw_wdd_faq_accordian"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Why Digital Marketing?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Digital marketing helps businesses reach their target customers online. It involves employing the latest SEO strategies, optimizing websites, and using social media and email marketing.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What are services in digital marketing?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Digital marketing boosts your business through SEO, social media, PPC, and more, attracting customers and driving growth.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Which service is best for digital marketing?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        The best service depends on your business’s digital marketing goals. For example, SEO allows long-term visibility, PPC quickly brings back results, and social media makes engagement possible. The combination of all these delivers the best for most businesses.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel4" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What does a digital marketer do?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        A digital marketer promotes businesses online by optimizing websites, managing social media, running ad campaigns, and analyzing data.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel5" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Is it worth paying for digital marketing?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Digital marketing does take measurable results by increasing the visibility of brands, bringing in customers, and increasing sales. It is a cost-effective way to achieve growth in an increasingly digital marketplace.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel6" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Would my business benefit from digital marketing?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Totally! Digital marketing would make a business of any kind able to find its audience, get them to visit, and then sell its product to them. In fact, whether the business is small or big, startup or established, it has become an integral part of building a presence in the online world.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel7"}
                    onChange={handleChange("panel7")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel7" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Is it worth paying for digital marketing?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Digital marketing does take measurable results by increasing the visibility of brands, bringing in customers, and increasing sales. It is a cost-effective way to achieve growth in an increasingly digital marketplace.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bw_services_form_section">
          <Container>
            <Row className="w-100 m-auto">
              <Col>
                <div
                  className="bw_contact_form_outer_box"
                >
                  <h2>Let's Book An Appointment</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitServicedetails}
                    >
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company Name"
                              value={business}
                              onChange={(e) => setBusiness(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                              disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={setPhone}
                              // onlyCountries={[
                              //   "us",
                              //   "cn",
                              //   "in",
                              //   "au",
                              //   "jp",
                              //   "hk",
                              //   "kw",
                              //   "gb",
                              //   "es",
                              // ]}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                console.log('value', value.length);
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}

                            />
                            {!phoneError.length ? '' : <p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Website"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>

                          <Form.Label>Services</Form.Label>
                          <FormGroup aria-label="position" row className="mb-3">
                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                label="Website Design & Development"
                                control={<Checkbox />}
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Website Design & Development")}
                              />
                            </Col>


                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Shopify Development"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Shopify Development")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Social Media Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Social Media Marketing")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Search Engine Optimization"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Search Engine Optimization")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Email Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Email Marketing")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Paid Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Paid Marketing")}
                              />
                            </Col>
                          </FormGroup>

                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? true : false}>
                          {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#FFFFFF"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "submit"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(Services);
