import React from "react";
import { helmet_url } from "../../Helpers/request";
import AboutCompany from "./AboutCompany";
import "../../Assets/css/home.css";
import NewBranding from "./NewBranding";
import Banner from "./Banner";
import BrandingText from "./BrandingText";
import BrandLogoSlider from "./BrandLogoSlider";
import InnovationVideo from "./InnovationVideo";
import ProjectSlider from "./ProjectSlider";
import ReviewSlider from "./ReviewSlider";
import "aos/dist/aos.css";
import AOS from "aos";
import News from "./News";
import Insta from "./insta";
import Helmet from "react-helmet";
import ShopifyPage from "./ShopifyPage";
import StoreMap from "../StoreMap";
import GlobeComponent from "../../GloabMap/GlobeComponent";
import ChristmasVideo from "./ChristmasVideo";


const Home = () => {

  
    React.useEffect(() => {
      AOS.init({
        duration: 1000,
        disable: window.innerWidth < 768,
      });
      }, []);
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jewelry Website Design & Marketing Company - Belgium WebNet</title>
        <meta
          name="description"
          content="Belgium WebNet is a jewelry web design & digital marketing company. We create user-friendly and optimized websites for your jewelry business. Contact Us!"
        ></meta>
         <meta name="keywords" content="jewelry website design company"></meta>
        <meta
          name="facebook-domain-verification"
          content="cmye64epq2guxvyxet66ktyxcplney"
        ></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_India" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content=">Expert Jewelry Website Design Company | Belgium WebNet"
        />
        <meta
          property="og:description"
          content="Belgium WebNet is a jewelry web design & digital marketing company. We create user-friendly and optimized websites for your jewelry business. Contact Us!"
        />
        <meta property="og:url" content={helmet_url} />
        <meta property="og:site_name" content="Belgium Webnet" />
        
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Banner />
      {/* <ChristmasVideo/> */}
      
      <NewBranding />
      <BrandLogoSlider/>
      <ShopifyPage/>
      {/* <BrandingText /> */}
      {/* <InnovationVideo /> */}
      {/* <AboutCompany /> */}
      <ProjectSlider />
      <ReviewSlider />
      {/* <News/> */}
      <GlobeComponent/>
      <Insta />
    </>
  );
};

export default Home;
