import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { baseURL, postHeader } from "../../../Helpers/request";
import swal from "sweetalert";
import "../../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { useHistory } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { TramRounded } from "@material-ui/icons";
import {isMobileOnly} from 'react-device-detect'
import ReactPlayer from 'react-player/lazy'
const CrGems = () => {
    const history = useHistory();
    const captchaRef = useRef(null);
    const [name, setname] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setmessage] = useState("");
    const [load, setLoad] = useState(false);
    const [captcha, setCaptcha] = React.useState(true);
    function onChange(value) {
        if (value) {
            setCaptcha(false);
        }
    }


    const SubmitAppointment = (e) => {
        e.preventDefault();
        captchaRef.current.reset();
        const data = {
            name: name,
            company: company,
            email: email,
            phone: phone.length ? `+${phone}` : "",
            message: message
        };
        setLoad(true);
        axios
            .post(`${baseURL}/contact/send_crgems_enquiry`, data, {
                headers: postHeader,
            })
            .then((res) => {
                if (res.data.status == 1) {
                    setname("");
                    setCompany("");
                    setEmail("");
                    setPhone("");
                    setmessage("");
                    history.push('./thankyou')
                } else {
                    swal(res.data.message, { icon: "warning" });
                    setLoad(false);
                }
            })
            .catch((error) => {
                if (error) {
                    swal("something went wrong", { icon: "error" });
                    setLoad(false);
                }
            });
    };

   
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Belgium WebNet- CR Gems
                </title>
                <meta
                    name="description"
                    content="The best digital marketing service provider to grow your business online. Belgium WebNet is the best social media marketing company to conquer social media."
                ></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <section className="bw_services_section_main bw_cr_gems_section">
                <div className="bw_all_banner bw_banner_pricing">
                    <Container >
                        <Row className="w-100 m-auto">
                            <Col>
                                <h1 className="bw_banner_heading">CR Gems API Integration </h1>
                                {/* <p className="bw_banner_sub_heading">
                                    Nivoda has joined hands with Belgium WebNet to help you

                                </p> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <div className="bw_gems_video_section">
<Container fluid>
<Row>
    <Col sm={12} md={12} lg={12}>
       
        {/* <div class=" wistia_responsive_padding" ><div class="wistia_responsive_wrapper" ><iframe src="https://fast.wistia.net/embed/iframe/aspgccldgn?seo=false&videoFoam=true" title="CRGemsBanner Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" className="wistia_embed video_gems" name="wistia_embed" msallowfullscreen width="100%" height="500"></iframe></div></div> */}

    {isMobileOnly ?<div class="wistia_responsive_padding"><div class="wistia_responsive_wrapper pt-3" ><div class="wistia_embed wistia_async_aspgccldgn seo=false videoFoam=true"> </div></div></div> :

//  <video  className="video_gems" height="500px" loop="true" width="100%" muted="" playsinline="" autoplay="">
// <source src="https://www.cr-gems.com/Content/Images/CRGemsBanner.mp4" type="video/mp4"/>
// <source src="https://www.cr-gems.com/Content/Images/CRGemsBanner.mp4" type="video/ogg"/>
// Your browser does not support the video tag.
// </video>
<ReactPlayer
url="https://www.cr-gems.com/Content/Images/CRGemsBanner.mp4"
width="100%"
height="500px"
poster="https://www.cr-gems.com/Content/Images/CRGemsBanner.mp4"
playing
loop
muted
controls={false}
playsinline
// preload="none"
fallback={()=>'loading'}
className="video_gems"
// className={`${props?.height && 'object-fit-cover'}`}
/>
    }  





    </Col>
</Row>
</Container>
            </div>

            <section className="apiSec">
                <Container className="bw_custome_container">
                    <div className="apiSecUnder cr_gems_sectionn">
                        <Row className="align-items-center">
                            <Col lg={6} md={12}>

                                <Row>
                                    <Col lg={11}>
                                        <div className="imgWrp">
                                            <img src={require('../../../Assets/images/integrations/cr-gems-img.png')} className="img-fluid" alt="" />
                                        </div>


                                        <h3 className="colorBlue mb-3">
                                        Get CR Gems Gemstone Feed & APIs Integrated on your website With Belgium WebNet
                                        {/* <span className="belgium">Belgium</span> <span className="webnet">WebNet</span> */}
                                        </h3>
                                        <p className="mb-0 fs15">
                                        Are you a Jeweller looking to expand your online inventory of Gemstones? CR Gems has joined hands with Belgium WebNet to help you effortlessly integrate <strong> CR Gems</strong>  feeds and APIs into your website, they have thousands of Gemstones available with <strong>360° interactive videos </strong> and images with competitive prices, they are located in New York, London & Birmingham.
                                        </p>

                  

                                        <div className="btnsec d-flex justify-content-between flex-wrap w-100 align-items-center">
                                            <div className="bw_contact_us_form ">
                                                <AnchorLink className="btnStyle" href='#start'>  Get Started</AnchorLink>
                                            </div>
                                            {/* <div>
                                                <img className="img-fluid" src={require('../../../Assets/images/integrations/diamond_11.jpg')} alt="" />
                                            </div> */}
                                        </div>
                                    </Col>
                                </Row>

                           



      


                            </Col>
                            <Col lg={6} md={12}>
                                <div className="positon-relative">
                                    <img className="mob_niv" src={require('../../../Assets/images/integrations/gems-mob.png')} alt="" />
                                 {isMobileOnly ?
                                 <div class="wistia_responsive_padding"><div class="wistia_responsive_wrapper">
                                    <div class="wistia_embed wistia_async_f1f1dhh0j3 seo=false videoFoam=true" >&nbsp;
                                    </div>
                                    </div>
                                    </div>
                                 
                                 : 
                                 
                                        <video className="w-100" muted loop autoPlay={true}>
                                        <source src={require('../../../Assets/images/integrations/cr-gems.mp4')} />
                                    </video>
                             }  
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>

            </section>

            <section className="bw_sercices_image_section bgDarkBlue1 " id="start">
                <Container className="">
                    <Form className="bw_contact_us_form" onSubmit={SubmitAppointment}>
                        <div className="bw_contact_form_outer_box mb-4">
                            <h2 className="w-100 mb-5">
                            Let's start your Integration
                            </h2>
                            <div className="formWrapper1 mb-4">
                                <div className="bw_contact_form_inner_box">
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className="input-control mb-4">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Name"
                                                        value={name}
                                                        onChange={(e) => setname(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className="input-control mb-4">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Company Name*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Company Name"
                                                        value={company}
                                                        onChange={(e) => setCompany(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className="input-control mb-4">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Email Id"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className="input-control mb-4">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <PhoneInput
                                                        disableSearchIcon={true}
                                                        enableSearch={true}
                                                        country={"us"}
                                                        value={phone}
                                                        onChange={setPhone}
                                                        // onlyCountries={[
                                                        //     "us",
                                                        //     "cn",
                                                        //     "in",
                                                        //     "au",
                                                        //     "jp",
                                                        //     "hk",
                                                        //     "kw",
                                                        //     "gb",
                                                        //     "es",
                                                        // ]}
                                                        inputProps={{
                                                            name: "phone",
                                                            required: true,
                                                            autoFocus: false,
                                                        }}
                                                        isValid={(value, country) => {
                                                            if (value.length < 10) {
                                                                return "please enter valid number";
                                                            } else {
                                                                return true;
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12}>
                                            <div className="input-control mb-4">
                                                <label>Type in your comment or query…</label>
                                                <textarea value={message} onChange={(e) => setmessage(e.target.value)} name="" id="" className="form-control radius10" ></textarea>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={12}>
                                            <div className="rcs_cap_main">
                                                <ReCAPTCHA
                                                    className="rcs_g-recaptcha"
                                                    ref={captchaRef}
                                                    sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                               
                                    <button disabled={load || captcha ? true : false}>
                                        {load ? (
                                            <div className="bw_loader_style">
                                                <Loader
                                                    type="spinner-default"
                                                    bgColor={"#FFFFFF"}
                                                    color={"#FFFFFF"}
                                                    size={20}
                                                />
                                            </div>
                                        ) : (
                                            "submit"
                                        )}
                                    </button>
                                </div>



                            </div>
                        </div>

                    </Form>
                </Container>
            </section>

        </>
    );
};

export default React.memo(CrGems);
