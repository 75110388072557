import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../Assets/css/shopifyPage.css";
import ShopifyRightImage1 from "../../Assets/images/shopify-img/sp1.webp";
import ShopifyRightImage2 from "../../Assets/images/shopify-img/sp2.webp";
import ShopifyLogo from "../../Assets/images/shopify-img/shopifyf.webp";
import WordpressLogo from "../../Assets/images/shopify-img/wordpressf.webp"; 
const ShopifyPage = () => { 
  const history = useHistory();
  return (
    <>
      <div className="bwn_shopify_page_section position-relative">
      <div className="bwn_shopify_logo_in_mobileView">
              <Image src={ShopifyLogo} alt="shopify Logo image" />
            </div>
            <div className="bwn_wp_logo_in_mobileView ">
              <Image src={WordpressLogo} alt="WordPress" />
            </div>
        <Container className="bw_custome_container">
          <Row>
            <Col>
              <div className="bwn_sopify_text_box_inner bwn_sopify_heading text-center">
                {/* <h6>We’re Leading</h6> */}
                <h4>Hire The Leading Shopify & WordPress Experts </h4>
              </div>
            </Col>
          </Row>
          <Row className="position-relative">
          
            <Col
              sm={12}
              md={6}
              lg={6}
              className="bwn_sopify_text_box_outer_right"
            >
              <div className="bwn_shopify_outer_box"> 
                <div className="d-flex justify-content-center">
                  <Image
                    src={ShopifyRightImage1}
                    className="img-fluid"
                    alt="Shopify Ring Builder Image"
                  />
                </div>
                <div className="bwn_sopify_text_box_inner">
                  <h4>
                  {/* Shopify & WordPress Ring Builder App */}
                     Ring Builder
                    </h4>
                  <p>
                    Give your customers the freedom to design their rings. The
                    Shopify & WordPress ring builder app will enable users to
                    customise their rings while allowing a hassle-free shopping
                    experience.{" "}
                  </p>
                  {/* <div class="bw_btb_border_white text-center btn_none_mobile"><button type="button" class="bw_custom_btn btn btn-primary" onClick={()=>history.push("/consultation")}>Get A Free Consultation</button></div> */}
                </div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="bwn_sopify_text_box_outer_right1 bwn_order1"
            >
              <div className="bwn_shopify_outer_box">
                <div className="d-flex justify-content-center">
                  <Image
                    src={ShopifyRightImage2}
                    className="img-fluid"
                    alt="Shopify Diamond Search Image"
                  />
                </div>
                <div className="bwn_sopify_text_box_inner">
                  {/* <h4>Shopify & WordPress Diamond Search</h4> */}
                  <h4> Diamond & Gemstone Search</h4>
                  <p>
                    Diamond navigation made easy! Shopify & WordPress diamond
                    search feature will ensure that your customers have easy
                    access to your diamonds whenever they need them.
                  </p>
                  {/* <div class="bw_btb_border_white text-center " ><button type="button" class="bw_custom_btn btn btn-primary" onClick={()=>history.push("/consultation")}>Get A Free Consultation</button></div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ShopifyPage;
