import React from "react";
import "../../Assets/css/blog1.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { NavLink, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { postHeader } from "../../Helpers/request";
import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import { baseURL } from "../../Helpers/request";

const BlogDetails = (props) => {
  const history = useHistory();

  const { id } = useParams();
  const path = useLocation();
  const [singleblog, setSingleblog] = useState([])
  const [blog, setBlog] = React.useState([]);
  const [blog1, setBlog1] = React.useState([]);
  const [blog2, setBlog2] = React.useState([]);
  const [blog3, setBlog3] = React.useState([]);
  const [blogurl1, setBlogurl1] = React.useState("");
  const [blogurl2, setBlogurl2] = React.useState("");
  const [blogurl3, setBlogurl3] = React.useState("");

  useEffect(() => {
    getDetails();
  }, [path])
  const getDetails = () => {
    axios.post('https://belgiumwebnet.com/webapi/api/blog/blog_detail', { slug: path.pathname.slice(7) }, {
      headers: postHeader
    }).then(res => {
      //console.log('res',res.data.status);
      if (res && res.data.status === 0) {
        history.push('/404');
      }
      setSingleblog(res.data.data)
    })
  }

  React.useEffect(() => {
    getBlog();
  }, [blogurl1, blogurl2, blogurl3]);
  const getBlog = () => {
    axios
      .get(`${baseURL}/blog/listing`)
      .then((blog) => {
        if (blog.data.status == 1) {
          setBlog(blog.data.data);
          setBlog1(blog.data.data[0]);
          setBlog2(blog.data.data[1]);
          setBlog3(blog.data.data[2]);
          setBlogurl1(
            `/blogs/${blog.data.data[0].blog_url}`
          );
          setBlogurl2(
            `/blogs/${blog.data.data[1].blog_url}`
          );
          setBlogurl3(
            `/blogs/${blog.data.data[2].blog_url}`
          );
        } else {
          swal(blog.data.message, { icon: "warning" });
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
        }
      });
  };


  var date = new Date(singleblog.date).toDateString();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{singleblog.meta_title}</title>
        <meta name="description" content={singleblog?.meta_desc}></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_blog_section">
        <div className="bw_all_banner bw_blog1_banner_padding">
          <Container className="bw_custome_container">
            <Row>
              <Col>
                <div className="bw_blog1_banner_height"></div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_blog1_image_section">
          <Container className="bw_custome_container">
            <Row >
              <Col sm={8} className="pr-50">
                <NavLink to="#">
                  <LazyLoadImage
                    src={singleblog.image}
                    alt={singleblog?.img_alt}
                    className="img-fluid w-100  mb-4"
                  />
                </NavLink>

                <div className="bw_blog1_btn_box">
                  <div>
                    <h6 className="bw_blog_post_date">
                      <span className="bw_post_date"> {date}</span>
                    </h6>
                  </div>
                </div>
                <div className="bw_blog1_content_box">
                  <NavLink
                    to={`/blogs/${singleblog.blog_url}`}
                  >
                    <h1>{singleblog.heading}</h1>
                  </NavLink>
                  <p dangerouslySetInnerHTML={{ __html: singleblog.content }} />
                  <p>
                    {/* {singleblog[2]} */}
                  </p>
                </div>

              </Col>
              <Col
                sm={4}
                className="mb-md-4"
              >
                <div
                  className="bw_recent_blog_heading"
                >
                  <h2>Recent</h2>
                </div>
                 <div className="bw_recent_blog1 mb-5">
                  <NavLink to={blogurl1}>
                    <LazyLoadImage src={blog1.image} alt="Blog Image" />
                  </NavLink>
                  <h6 className="bw_blog_post_date">
                    {blog1.name}{" "}
                    <span className="bw_post_date"> {blog1.date}</span>
                  </h6>
                  <NavLink to={blogurl1}>
                    <h4>{blog1.heading}</h4>
                  </NavLink>
                  {/* <p className="datalimit" dangerouslySetInnerHTML={{ __html: blog1.content }} /> */}

                </div>
                <Row className="bw_blog_post_inner_box">
                  <Col sm={12} md={12} lg={5} className="p-lg-0">
                    <NavLink to={blogurl2}>
                      <LazyLoadImage src={blog2.image} alt="Blog Image" />
                    </NavLink>
                  </Col>
                  <Col sm={12} md={12} lg={7}>
                    <div className="bw_bpost_box_inner">
                      <h6 className="bw_blog_post_date">
                        {blog2.name}{" "}
                        <span className="bw_post_date">{blog2.date}</span>
                      </h6>
                      <NavLink to={blogurl2}>
                        <h4>{blog2.heading}</h4>
                      </NavLink>
                      {/* <p className="datalimit" dangerouslySetInnerHTML={{ __html: blog2.content }} /> */}
                    </div>
                  </Col>
                </Row>
                <Row className="bw_blog_post_inner_box mt-4">
                  <Col sm={12} md={12} lg={5} className="p-lg-0">
                    <NavLink to={blogurl3}>
                      <LazyLoadImage src={blog3.image} alt="Blog Image" />
                    </NavLink>
                  </Col>
                  <Col sm={12} md={12} lg={7}>
                    <div className="bw_bpost_box_inner">
                      <h6 className="bw_blog_post_date">
                        {blog3.name}{" "}
                        <span className="bw_post_date">{blog3.date}</span>
                      </h6>
                      <NavLink to={blogurl3}>
                        <h4>{blog3.heading}</h4>
                      </NavLink>
                      {/* <p className="datalimit" dangerouslySetInnerHTML={{ __html: blog3.content }} /> */}
                      {/* <p>{blog3.content}</p> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Recent blog end */}
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(BlogDetails);
