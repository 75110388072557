import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import { isDesktop } from "react-device-detect";
import Boutusbanner from "../../Assets/images/about-us/about-us-banner1.webp";
import Sami from "../../Assets/images/about-us/sami.webp";
import Sonu from "../../Assets/images/about-us/sonu.webp";
import Aashish from "../../Assets/images/about-us/ashish-new.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaLinkedinIn, FaInstagram, FaWhatsapp, FaFacebookF } from 'react-icons/fa';
import AboutusVideo from '../../Assets/about-us-banner-latest.mp4'
import FacbookImg from '../../Assets/images/social-img/facebook.png'
import InstagramImg from '../../Assets/images/social-img/instagram.png'
import WhatsappImg from '../../Assets/images/social-img/whatsapp.png'
import LinkedinImg from '../../Assets/images/social-img/linkedin.png'
import CalanderImg from '../../Assets/images/about-us/calendar.png'
import Zaid from "../../Assets/images/about-us/team/1.png"
import Simoli from "../../Assets/images/about-us/team/2.png"
import Deepangi from "../../Assets/images/about-us/team/3.png"
import Saba from "../../Assets/images/about-us/team/4.png"
import Shweta from "../../Assets/images/about-us/team/5.png"
import D1 from "../../Assets/images/about-us/team/d1.png"
import D2 from "../../Assets/images/about-us/team/d2.png"
import Desk1 from "../../Assets/images/about-us/team/desk1.png"
import Desk2 from "../../Assets/images/about-us/team/desk2.png"
import Desk4 from "../../Assets/images/about-us/team/aarti.png"
import Desk6 from "../../Assets/images/about-us/team/vashnavi.png"
import Desk7 from "../../Assets/images/about-us/team/desk7.png"
import Desk8 from "../../Assets/images/about-us/team/desk8.png"
import Desk9 from "../../Assets/images/about-us/team/Desk9.png";
import Desk10 from "../../Assets/images/about-us/team/desk10.png";
import { useHistory } from "react-router-dom";
import OurDecatedTeam from "./NewPricing/OurDecatedTeam";





const pc = [
  // {
  //   title: "Zaid Khan",
  //   position: "Technical Project Manager",
  //   ImgUrl: Zaid
  // },
  {
    title: "Simoli Sodhiya",
    position: "Sr. Project Coordinator",
    ImgUrl: Simoli
  },
  {
    title: "Deepangi Joshi",
    position: "Project Coordinator",
    ImgUrl: Deepangi
  },
  {
    title: "Shweta Raghuwanshi",
    position: "Project Coordinator",
    ImgUrl: Shweta
  },
  {
    title: "Saba Choudhary",
    position: "Project Coordinator",
    ImgUrl: Saba
  },
  
  
]

const dm = [
  {
    title: "Natasha Bajaj",
    position: "Digital Marketing Manager",
    ImgUrl: isDesktop ? Desk1 : D1
  },
  {
    title: "Zahid Zaidi",
    position: "Sr. Digital Marketer",
    ImgUrl: isDesktop ? Desk2 : D2
  },
  // {
  //   title: "Aarti Dattani",
  //   position: "Content Writer",
  //   ImgUrl: isDesktop ? Desk4 : Desk4
  // },
  {
    title: "Vaishnavi Mishra ",
    position: "Social Media Executive",
    ImgUrl: isDesktop ? Desk6 : Desk6
  },
  // {
  //   title: "Sineha Advani",
  //   position: "SEO Executive",
  //   ImgUrl: isDesktop ? Desk7 : Desk7
  // },
  {
    title: "Shivam Basedia",
    position: "PPC Expert",
    ImgUrl: isDesktop ? Desk8 : Desk8
  },
  {
    title: "Nishika Kundlani",
    position: "Social Media Executive",
    ImgUrl: isDesktop ? Desk9 : Desk9
  },

  //  {
  //   title: "Abhishree Nahar",
  //   position: "Social Media Executive",
  //   ImgUrl: isDesktop ? Desk10 : Desk10
  // },

]

const Aboutus = () => {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        About Us | Belgium WebNet
        </title>
        <meta
          name="description"
          content="Discover the vision and expertise behind Belgium WebNet – your partner in jewelry web development, digital marketing, and business growth strategies."
        ></meta>
        <meta name="keywords" content="belgium webnet, about us"></meta>
      </Helmet>
      <section className="bw_aboutus_section">
        <div className="bwn_about_us_banner">
          <Container fluid className="pl-0 pr-0">
            <div>
              <Image src={Boutusbanner} alt="About us Banner" className="img-fluid" />
            </div>
          </Container>
        </div>
        <div className="bwn_about_us_video">
          <video height="100%" loop="true" width="100%" muted="" playsinline="" autoplay="true">
            <source src={AboutusVideo} type="video/mp4" />
            <source src={AboutusVideo} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="bwn_new_about_us_text_box">
          <Container>
            <Row>
              <div className="bwn_bg_image_of_text">
                <h4>Our Story</h4>
                <p>We are the designers, marketers, innovators, strategists, and builders behind your virtual store.

                  A team of young enthusiasts who believes in creating one-of-a-kind solutions for you and your brand. From the very first building block to the final setup of your virtual realm, we make sure you never have a dull moment.

                  From design, development, and idea creation to implementation and marketing of your brands, Its ultimate transformation is the only thing we care about.

                  Challenges are what keep us going and therefore in our eyes, every new opportunity is a challenge and not a mere project.</p>
                <p>The 70+ employees that work with us are not mere corporate professionals but 70+ creative minds that bring the best to the table. If your brand is lacking creativity and professional reinforcement, with our team you get the best of both worlds.

                  In the 5 years of our existence, we have made a difference in the life of 150+ clients and we strive to increase this number by the day.

                  If your jewelry store or hair salon needs an extra pair of hands to do the backend work while you rock in the limelight, Belgium Webnet is the partner you have been looking for.</p>
              </div>
            </Row>
          </Container>
        </div>
        <div className="bwn_our_leadership_section">
          <Container>
            <Row>
              <h3>Our Leadership</h3>
              <ul className="pl-0">
                <li>
                  <div className="bwn_our_leadership_section_iiner_box">
                    <Image src={Sami} alt="Mamber Image" className="img-fluid" />
                    <h5>Sami Saghie</h5>
                    <p>CEO & Partner</p>
                    <div className="d-flex btn_outer_box">
                      <a className="bwn_member_button" href="https://www.linkedin.com/in/sami-saghie-9a860021/" target="_blank" ><Image src={LinkedinImg} alt="Linkedin Image" /></a>
                      <a className="bwn_member_button" href="https://instagram.com/sami_n_y_c?igshid=YmMyMTA2M2Y=" target="_blank" ><Image src={InstagramImg} alt="Instagram Image" /></a>
                      <a className="bwn_member_button" href="https://wa.me/+19177677248" target="_blank" ><Image src={WhatsappImg} alt="Whats App Image" /></a>
                      <div className="bwn_member_button" title="Book An Appointment" ><Image src={CalanderImg} alt="Book An Appointment" onClick={() => history.push("/about-us/sami-saghie")} /></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="bwn_our_leadership_section_iiner_box">
                    <Image src={Sonu} alt="Mamber Image" className="img-fluid" />
                    <h5>Sonu Kothari</h5>
                    <p>Co-Founder</p>
                    <div className="d-flex btn_outer_box">
                      <a className="bwn_member_button" href="https://www.linkedin.com/in/sonu-kothari-a6a0926a/" target="_blank" ><Image src={LinkedinImg} alt="Linkedin Image" /></a>
                      <a className="bwn_member_button" href="https://instagram.com/sonukothari?igshid=YmMyMTA2M2Y=" target="_blank" ><Image src={InstagramImg} alt="Instagram Image" /></a>
                      <a className="bwn_member_button" href="https://www.facebook.com/sonu.kothari.9047?mibextid=ZbWKwL" target="_blank" ><Image src={FacbookImg} alt="Instagram Image" /></a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="bwn_our_leadership_section_iiner_box">
                    <Image src={Aashish} alt="Mamber Image" className="img-fluid" />
                    <h5>Ashish Jangir</h5>
                    <p>CTO & Co-Founder</p>
                    <div className="d-flex btn_outer_box">
                      <a className="bwn_member_button" href="https://www.linkedin.com/in/ashish-jangir-5735527a/" target="_blank" ><Image src={LinkedinImg} alt="Linkedin Image" /></a>
                      <a className="bwn_member_button" href="https://www.instagram.com/belgium_webnet/" target="_blank" ><Image src={InstagramImg} alt="Instagram Image" /></a>
                      <a className="bwn_member_button" href="https://wa.me/+16469929024" target="_blank" ><Image src={WhatsappImg} alt="Whats App Image" /></a>
                      <div className="bwn_member_button" title="Book An Appointment" ><Image src={CalanderImg} alt="Book An Appointment" onClick={() => history.push("/about-us/ashish-jangir")} /></div>
                    </div>
                  </div>
                </li>
              </ul>
            </Row>
          </Container>
        </div>

      </section>

      <section className="project_management_team_section">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="bwn_our_leadership_section">
                <h3>Project Management Team</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="p_team_main">

                {pc.map((valu, index) => (
                  <div className="project_management_team_box">
                    <img src={valu.ImgUrl} />
                    <div className="p_team_text_content">
                      <h2> {valu.title} </h2>
                      <p>{valu.position}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section className="project_management_team_section marketing_section">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="bwn_our_leadership_section">
                <h3>Marketing Team</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12} className="">
              <div className="p_team_main marketing_team_main marketing_team">

                {dm.map((valu, index) => (
                  <div className="project_management_team_box">
                    <img src={valu.ImgUrl} />
                    <div className="p_team_text_content">
                      <h2> {valu.title} </h2>
                      <p>{valu.position}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>

 
      </section>
<OurDecatedTeam/>

    </>
  );
};

export default React.memo(Aboutus);
