import React, { useRef } from "react";
import "../../Assets/css/contactus.css";
import "../../Assets/css/paidPlansSmo.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import Helmet from "react-helmet";
import consultationImg from "../../Assets/images/forms-banner/consultation-img.webp";
import "../../Assets/css/consultation.css";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from "react-router-dom";

const PaidPlansSmo = (props) => {
  
  const planName= useLocation();
  console.log(" const planName= useLocation();", planName)
  const captchaRef = useRef(null)
  const [firstname, setFirstname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [business, setBusiness] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha,setCaptcha] = React.useState(true)
  function onChangeCaptcha(value) {
    if(value){
      setCaptcha(false)
    }
  }

  const SubmitMembership = (e) => {
    console.log('isS');
    e.preventDefault();
  //captchaRef.current.reset();
  const data = {
    first_name: firstname,
    email: email,
    phone: `+${phone}`,
    business_name: business,
    plan_type: planName.state.planName,
  }; 

    setLoad(true);
    axios
      .post(`${baseURL}/membership/plan_request`, data, { headers: postHeader })
      .then((res) => {
        if (res.data.status == 1) {
          swal(res.data.message, { icon: "success" });
          setFirstname("");
          setPhone("");
          setEmail("");
          setBusiness("");
          setLoad(false);
        } else {
          swal(res.data.message, { icon: "warning" });
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
          setLoad(false);
        }
      });
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Belgium WebNet- We Offer Consultation To Grow Your Business Online
        </title>
        <meta
          name="description"
          content="Consult with our experts to grow your business online. Get afoordable digital marketing and web development services. Reach and widen your target audience."
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_contactus_section position-relative">
        <div className="bw_all_banner bw_contact_banner bw_consultation_banner">
          <Container>
            <Row>
              <Col
                sm={12}
                md={12}
                lg={12}
                className="bw_consultation_banner_text"
              >
                <div>
                  <h1 className="text-center text-white">
                    Subscribe To succeed!
                  </h1>
                  <p className="text-white text-center">
                    Gear Up To Witness Your Business Conquer Social Media
                  </p>
                </div>
              </Col>
              {/* <Col sm={12} md={6} lg={6} className="bw_consultation_banner_img">
                <LazyLoadImage src={consultationImg} alt="Consultation Image" />
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="bw_new_position_box bw_consulation_from_position mb-5">
          <Container>
            <Row className="w-100 m-auto px-0">
              <Col className="px-0">
                <div
                  className="bw_contact_form_outer_box"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {/* <h2>Let's Book An Appointment</h2> */}
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitMembership}
                    >
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number*</Form.Label>
                            <PhoneInput
                            disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={setPhone}
                              // onlyCountries={[
                              //   "us",
                              //   "cn",
                              //   "in",
                              //   "au",
                              //   "jp",
                              //   "hk",
                              //   "kw",
                              //   "gb",
                              //   "es",
                              // ]}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus:false,
                              }}
                              isValid={(value, country) => {
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company Name"
                              value={business}
                              onChange={(e) => setBusiness(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col sm={12} md={6} lg={6}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Link Of Your Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Link Of Your Website"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}

                        {/* <Col sm={12} md={12} lg={12}>
                          <div className="bw_paid_plans_checkbox_outer">
                            <FormLabel component="legend">
                              <h6> Which Plan Would You Like To Opt For? </h6>
                            </FormLabel>
                            <div className="d-flex justify-content-center">
                              <FormGroup
                                aria-label="position"
                                row
                                value={plan}
                                onChange={checkBox}
                                required
                              >
                                <FormControlLabel
                                  value="Gold"
                                  control={<Checkbox />}
                                  label="Gold"
                                  labelPlacement="start"
                                />
                                <FormControlLabel
                                  value="Platinum"
                                  control={<Checkbox />}
                                  label="Platinum"
                                  labelPlacement="start"
                                />
                                <FormControlLabel
                                  value="Titanium"
                                  control={<Checkbox />}
                                  label="Titanium"
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </Col> */}
                        <ReCAPTCHA
                          sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                          onChange={onChangeCaptcha}
                        />
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? true : false}
>{load ?  <div className="bw_loader_style"><Loader type="spinner-default" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={20} /></div> : 'submit'}</button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(PaidPlansSmo);
